var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section-padding marche",staticStyle:{"margin-top":"7%","padding-top":"3%"},attrs:{"id":"features-app"}},[_c('div',{staticClass:"container "},[_c('div',{staticClass:"col-md-12",staticStyle:{"margin-bottom":"5%"}},[_c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"titleSection"},[_vm._v(_vm._s(_vm.$t('homePage.stepsSection.title')))]),_c('img',{staticClass:"img-title",attrs:{"src":"img/heartbeat.png"}}),_c('div',[_vm._v(_vm._s(_vm.$t('homePage.stepsSection.subtitle')))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-6 col-xs-12 responsive-margin",on:{"mouseover":function($event){_vm.imgDoc0 = require('./../../../public/img/user/set1.png');
            _vm.pDoc0='p-show';
            _vm.pClass0='p-selected';
            _vm.imgStyle0=''},"mouseleave":function($event){_vm.imgDoc0 = require('./../../../public/img/user/inscrire.svg');   
            _vm.pDoc0='p-hide';
            _vm.pClass0='p-Notselected';
            _vm.imgStyle0='img-style0'}}},[_c('div',{staticClass:"medecin-pro medecin-pro0"},[_c('div',{},[_c('img',{class:_vm.imgStyle0,attrs:{"src":_vm.imgDoc0,"alt":""}})]),_c('p',{class:_vm.pClass0},[_vm._v(_vm._s(_vm.$t('homePage.stepsSection.images[0].title')))]),_c('p',{class:_vm.pDoc0},[_vm._v(_vm._s(_vm.$t('homePage.stepsSection.images[0].description')))])])]),_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-6 col-xs-12 responsive-margin",on:{"mouseover":function($event){_vm.imgDoc1 = require('./../../../public/img/user/set1.png');
                        _vm.pDoc1='p-show';
                        _vm.pClass1='p-selected';
                        _vm.imgStyle1=''},"mouseleave":function($event){_vm.imgDoc1 = require('./../../../public/img/user/medecin-pro.png');   
                        _vm.pDoc1='p-hide';
                        _vm.pClass1='p-Notselected';
                        _vm.imgStyle1='img-style1'}}},[_c('div',{staticClass:"medecin-pro medecin-pro1"},[_c('div',{},[_c('img',{class:_vm.imgStyle1,attrs:{"src":_vm.imgDoc1,"alt":""}})]),_c('p',{class:_vm.pClass1},[_vm._v(_vm._s(_vm.$t('homePage.stepsSection.images[1].title')))]),_c('p',{class:_vm.pDoc1},[_vm._v(_vm._s(_vm.$t('homePage.stepsSection.images[1].description')))])])]),_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-6  col-xs-12 responsive-margin",on:{"mouseover":function($event){_vm.imgDoc2 = require('./../../../public/img/user/set2.png');
                            _vm.pDoc2='p-show';
                            _vm.pClass2='p-selected';
                            _vm.imgStyle2=''},"mouseleave":function($event){_vm.imgDoc2 = require('./../../../public/img/user/rendez-vous.png');   
                            _vm.pDoc2='p-hide';
                            _vm.pClass2='p-Notselected';
                            _vm.imgStyle2='img-style2'}}},[_c('div',{staticClass:"medecin-pro medecin-pro2"},[_c('div',{},[_c('img',{class:_vm.imgStyle2,attrs:{"src":_vm.imgDoc2,"alt":""}})]),_c('p',{class:_vm.pClass2},[_vm._v(_vm._s(_vm.$t('homePage.stepsSection.images[2].title')))]),_c('p',{class:_vm.pDoc2},[_vm._v(_vm._s(_vm.$t('homePage.stepsSection.images[2].description')))])])]),_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-6 col-xs-12 responsive-margin",on:{"mouseover":function($event){_vm.imgDoc3 = require('./../../../public/img/appointmentconfimed.png');
                            _vm.pDoc3='p-show';
                            _vm.pClass3='p-selected';
                            _vm.imgStyle3=''},"mouseleave":function($event){_vm.imgDoc3 = require('./../../../public/img/user/confirmer.png');   
                            _vm.pDoc3='p-hide';
                            _vm.pClass3='p-Notselected';
                            _vm.imgStyle3='img-style3'}}},[_c('div',{staticClass:"medecin-pro medecin-pro3"},[_c('div',{},[_c('img',{class:_vm.imgStyle3,attrs:{"src":_vm.imgDoc3,"alt":""}})]),_c('p',{class:_vm.pClass3},[_vm._v(_vm._s(_vm.$t('homePage.stepsSection.images[3].title')))]),_c('p',{class:_vm.pDoc3},[_vm._v(_vm._s(_vm.$t('homePage.stepsSection.images[3].description')))])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }