import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "axios";
import { asyncLoading } from "vuejs-loading-plugin";
Vue.component("multiselect", Multiselect);

export default {
  name: "loginAndRegisterDoctorSection",
  components: { Multiselect, asyncLoading },
  data() {
    let options = this.$t('specList')
    return {
      value: [],
      options: options,
      typeFormDocor: 2,
      dataToSendSignUpDoctor: {
        firstName: "",
        lastName: "",
        email: "",
        dateOfBirth: "",
        gender: "homme",
        phoneNumber: "",
        specialty: [],
        address: "",
        postalCode: "",
        city: "Alger",
        password: "",
        confirmPassword: "",
        role: "doctor"
      },
      BorderSignUp: {
        firstName: "",
        lastName: "",
        email: "",
        dateOfBirth: "",
        phoneNumber: "",
        specialty: "",
        address: "",
        postalCode: "",
        city: "",
        password: "",
        confirmPassword: ""
      },
      dataToSendLoginDoctor: {
        email: "",
        password: ""
      },
      dataToSendPasswordDoctor: {
        email: ""
      }
    };
  },
  methods: {
    directionClass() {
      if (this.$store.state.locale == 'ar') return 'directionRight form-elements'
      else return 'directionLeft form-elements'
    },
    checkMultiSelect(value) {
      console.log(value, this.value);
    },
    sendEmail() {
      this.$loading(true);
      const login = new Promise((resolve, reject) => {
        axios
          .post(
            "https://algedoc-backend.herokuapp.com/api/user/recoverPasswordRequest ",
            this.dataToSendPasswordDoctor
          )
          .then(response => {
            this.$loading(false);
            this.$toast.open({
              message: this.$t('notifications.verificationEmailSent'),
              type: "success",
              duration: 7000
              // all of other options may go here
            });
          })
          .catch(error => {
            this.$loading(false);
            this.errorMessage = error.message;
            this.$toast.open({
              message: this.$t('notifications.verifyMail'),
              type: "error",
              duration: 7000
              // all of other options may go here
            });
          });
      });
    },
    connectUser() {
      this.$loading(true);
      const login = new Promise((resolve, reject) => {
        axios
          .post(
            "https://algedoc-backend.herokuapp.com/api/user/login",
            this.dataToSendLoginDoctor
          )
          .then(response => {
            this.$loading(false);
            this.$toast.open({
              message: this.$t('notifications.welcomeNotif'),
              type: "success",
              duration: 5000

              // all of other options may go here
            });
            console.log("login", response);
            this.$store.commit("SET_USER", response.data.user);
            this.$store.commit("SET_TOKEN", response.data.token);
            // this.$store.commit('setUserInfo',response.data.user)
            setTimeout(() => {
              if (!response.data.user.doctorAdminValidation) {
                this.$toast.open({
                  message:
                    this.$t('notifications.accountNotVerified'),
                  type: "info",
                  duration: 5000
                  // all of other options may go here
                });
              }
            }, 3000);
            this.$router.push({ name: "Home" });
          })
          .catch(error => {
            this.$loading(false);
            console.error("There was an error!", error);
            console.log(error.response);
            if (error.response.data.message == "ModelNotFound") {
              this.$toast.open({
                message: this.$t('notifications.accountDoesntExist'),
                type: "error",
                duration: 7000
              });
            } else {
              this.$toast.open({
                message: error.response.data.details,
                type: "error"
                // position: 'top-right'
                // all of other options may go here
              });
            }
          });
      });
    },
    addUser() {
      this.$loading(true);
      const login = new Promise((resolve, reject) => {
        if (this.validateSignUpForm()) {
          this.value.forEach(element => {
            this.dataToSendSignUpDoctor.specialty.push(element.code);
          });
          axios
            .post(
              "https://algedoc-backend.herokuapp.com/api/user/signup",
              this.dataToSendSignUpDoctor
            )
            .then(async response => {
              this.$loading(false);
              await this.$store.dispatch("createAdminRoom", response.data.id);
              this.$toast.open({
                message: this.$t('notifications.verificationEmailSent'),
                type: "success"
                // position: 'top-right'
              });
              this.dataToSendSignUpDoctor.firstName = ""
              this.dataToSendSignUpDoctor.lastName = ""
              this.dataToSendSignUpDoctor.email = ""
              this.dataToSendSignUpDoctor.dateOfBirth = ""
              this.dataToSendSignUpDoctor.gender = "homme"
              this.dataToSendSignUpDoctor.password = ""
              this.dataToSendSignUpDoctor.phoneNumber = ""
              this.dataToSendSignUpDoctor.specialty = []
              this.dataToSendSignUpDoctor.address = ""
              this.dataToSendSignUpDoctor.postalCode = ""
              this.dataToSendSignUpDoctor.city = "Alger"
              this.dataToSendSignUpDoctor.confirmPassword = ""
              this.dataToSendSignUpDoctor.role = "patient"
            })
            .catch(error => {
              this.$loading(false);
              this.errorMessage = error.message;
              console.error("There was an error!", error);
              console.log("error : ", error.response);
              this.$toast.open({
                message: error.response.data.details,
                type: "error",
                duration: 7000
                // position: 'top-right'
              });
            });
        }
      });
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const res = re.test(String(email).toLowerCase());
      if (res) {
        this.BorderSignUp.email = "border:1px solid #32a852";
      } else {
        this.BorderSignUp.email = "border:1px solid #ff0010";
      }
      console.log("res : ", res, this.BorderSignUp.email);
      return res;
    },
    validateFirstName(firstName) {
      if (firstName.length > 0) {
        this.BorderSignUp.firstName = "border:1px solid #32a852";
        return true;
      }
      this.BorderSignUp.firstName = "border:1px solid #ff0010";
      return false;
    },
    validateLastName(lastName) {
      if (lastName.length > 0) {
        this.BorderSignUp.lastName = "border:1px solid #32a852";
        return true;
      }
      this.BorderSignUp.lastName = "border:1px solid #ff0010";
      return false;
    },
    validateDateOfBirth(dateOfBirth) {
      if (dateOfBirth.length > 0) {
        this.BorderSignUp.dateOfBirth = "border:1px solid #32a852";
        return true;
      }
      this.BorderSignUp.dateOfBirth = "border:1px solid #ff0010";
      return false;
    },
    validatePassword(password, confirmPassword) {
      if (password != confirmPassword) {
        this.BorderSignUp.password = "border:1px solid #ff0010";
        this.BorderSignUp.confirmPassword = "border:1px solid #ff0010";
        return false;
      }
      if (password.length == 0) {
        this.BorderSignUp.password = "border:1px solid #ff0010";
        this.BorderSignUp.confirmPassword = "border:1px solid #ff0010";
        return false;
      }
      this.BorderSignUp.password = "border:1px solid #32a852";
      this.BorderSignUp.confirmPassword = "border:1px solid #32a852";
      return true;
    },
    validatePhoneNumber(phoneNumber) {
      if (phoneNumber.toString().length > 7) {
        this.BorderSignUp.phoneNumber = "border:1px solid #32a852";
        return true;
      }
      this.BorderSignUp.phoneNumber = "border:1px solid #ff0010";
      return false;
    },
    validateSpeciality(speciality) {
      console.log("seee", speciality);
      if (speciality.length > 0) {
        this.BorderSignUp.speciality =
          "border:1px solid #32a852;padding:0!important";
        return true;
      }
      this.BorderSignUp.specialty =
        "border:1px solid #ff0010;padding:0!important";
      return false;
    },
    validateAddress(address) {
      if (address.length > 0) {
        this.BorderSignUp.address = "border:1px solid #32a852";
        return true;
      }
      this.BorderSignUp.address = "border:1px solid #ff0010";
      return false;
    },
    validatePostalCode(postalCode) {
      if (postalCode.toString().length > 0) {
        this.BorderSignUp.postalCode = "border:1px solid #32a852";
        return true;
      }
      this.BorderSignUp.postalCode = "border:1px solid #ff0010";
      return false;
    },
    validateCity(city) {
      if (city.length > 0) {
        this.BorderSignUp.city = "border:1px solid #32a852";
        return true;
      }
      this.BorderSignUp.city = "border:1px solid #ff0010";
      return false;
    },
    validateSignUpForm() {
      console.log("hereeeee");
      let errorList = "";
      if (!this.validateFirstName(this.dataToSendSignUpDoctor.firstName)) {
        errorList += this.$t('attrs.surname') + ", ";
      }
      if (!this.validateLastName(this.dataToSendSignUpDoctor.lastName)) {
        errorList += this.$t('attrs.name') + ", ";
      }
      if (!this.validateEmail(this.dataToSendSignUpDoctor.email)) {
        errorList += "Email, ";
      }
      if (!this.validateDateOfBirth(this.dataToSendSignUpDoctor.dateOfBirth)) {
        errorList += this.$t('attrs.dateOfBirth') + ", ";
      }
      if (!this.validatePhoneNumber(this.dataToSendSignUpDoctor.phoneNumber)) {
        errorList += this.$t('attrs.phone') + ", ";
      }
      if (!this.validateSpeciality(this.value)) {
        errorList += this.$t('attrs.specialities') + ", ";
      }
      if (!this.validateAddress(this.dataToSendSignUpDoctor.address)) {
        errorList += this.$t('attrs.address') + ", ";
      }
      if (!this.validatePostalCode(this.dataToSendSignUpDoctor.postalCode)) {
        errorList += this.$t('attrs.codePostal') + ", ";
      }
      if (!this.validateCity(this.dataToSendSignUpDoctor.city)) {
        errorList += this.$t('attrs.city') + ", ";
      }
      if (
        !this.validatePassword(
          this.dataToSendSignUpDoctor.password,
          this.dataToSendSignUpDoctor.confirmPassword
        )
      ) {
        errorList += this.$t('patientsPage.links.password') + ", ";
      }
      if (errorList.length > 0) {
        this.$loading(false);
        this.$toast.open({
          message: this.$t('notifications.verifyFields') + errorList,
          type: "error",
          duration: 10000
          // position: 'top-right'
        });
        return false;
      }
      return true;
    },
    getSpecialities() {
      return this.options.map((option, index) => ({ name: option, code: index }))
    },
  }
};
