import VueAos from "vue-aos";
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "proSection",
  components: { VueAos, Carousel, Slide
  },
  data() {
    return {
      imgDoc0: require("./user/engage1.png"),
      pDoc0:'p-hide',
      pClass0:"p-Notselected",
      imgDoc1: require("./user/engage2.png"),
      pDoc1:'p-hide',
      pClass1:"p-Notselected",
      imgDoc2: require('./user/save-money-inactive.png'),
      pDoc2:'p-hide',
      pClass2:"p-Notselected",
      imgStyle0:"img-style0",
      imgStyle1:"img-style1",
      imgStyle2:"img-style2",
    }
  },
  methods:{
    // imgDoctor1(){
    //   return this.imgDoc1
    // }
  }
  
};