import faqSection from "../../components/faqSection/index.vue";
import newsLetters from "../../components/newsLetters/index.vue";
import partenairesSection from "../../components/partenairesSection/index.vue";
import heroSection from "../../components/heroSection/index.vue";

export default {
  name: "faqPage",
  components: {
    heroSection,
    faqSection,
    newsLetters,
    partenairesSection
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: 'FAQ page',
      })
      this.$gtag.pageview({
        page_path: '/faq',
      })
    }
  }
};
