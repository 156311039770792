import newsLetters from "../../components/newsLetters/index.vue";
import partenairesSection from "../../components/partenairesSection/index.vue";
import heroSection from "../../components/heroSection/index.vue";
import slideInformation from "../../components/slideInformation/index.vue";
import profileInformationsSection from "../../components/profileInformationsSection/index.vue";

export default {
  name: "userInfoPage",
  components: {
    slideInformation,
    heroSection,
    profileInformationsSection,
    newsLetters,
    partenairesSection
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: 'User info page',
      })
      this.$gtag.pageview({
        page_path: '/userinfo',
      })
    }
  }
};
