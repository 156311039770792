import heroSection from "../../components/heroSection/index.vue";
import loginAndRegisterUserSection from "../../components/loginAndRegisterUserSection/index.vue";


export default {
  name: "LoginAndRegisterUserPage",
  components: {
    heroSection,
    loginAndRegisterUserSection
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: 'User login registration page',
      })
      this.$gtag.pageview({
        page_path: '/patientlogin',
      })
    }
  }
};
