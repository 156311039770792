import axios from "axios";

export default {
  name: "loginAndRegisterUserSection",
  components: {},
  data: () => {
    return {
      typeForm: 2,
      dataToSendSignUp: {
        phoneNumber: "",
        firstName: "",
        lastName: "",
        email: "",
        dateOfBirth: "",
        gender: "homme",
        password: "",
        confirmPassword: "",
        role: "patient",
      },
      IconSignUp: {
        email: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        gender: '',
        password: '',
        confirmPassword: '',
        phoneNumber: ''
      },
      dataToSendLogin: {
        email: "",
        password: "",
      },
      dataToSendPassword: {
        email: "",
      }
    };
  },
  methods: {
    directionClass() {
      if (this.$store.state.locale == 'ar') return 'directionRight form-elements'
      else return 'directionLeft form-elements'
    },
    sendEmail() {
      this.$loading(true)
      const sendEmail = new Promise((resolve, reject) => {
        axios
          .post(
            "https://algedoc-backend.herokuapp.com/api/user/recoverPasswordRequest ",
            this.dataToSendPassword
          )
          .then((response) => {
            this.$loading(false)
            this.$toast.open({
              message: this.$t('notifications.resetPasswordMail'),
              type: 'success',
              duration: 8000
              // all of other options may go here
            });
          })
          .catch((error) => {
            this.$loading(false)
            this.errorMessage = error.message;
            this.$toast.open({
              message: this.$t('notifications.verifyMail'),
              type: 'error',
              duration: 4000
              // all of other options may go here
            });
          });
      })
    },
    initSocket() {
      this.$store.dispatch('connectSocket')
      this.$store.state.socket.on("appointmentAccepted", (notif) => {
        this.$toast.open({
          message: notif.description,
          type: "success",
          duration: 5000
          // position: "top-right",
        });
      });
      this.$store.state.socket.on("appointmentDeclined", (notif) => {
        this.$toast.open({
          message: notif.description,
          type: "error",
          duration: 5000
          // position: "top-right",
        });
      });
    },
    connectUser() {
      this.$loading(true)
      const connectUser = new Promise((resolve, reject) => {
        axios
          .post(
            "https://algedoc-backend.herokuapp.com/api/user/login",
            this.dataToSendLogin
          )
          .then((response) => {
            this.$loading(false)
            this.$toast.open({
              message: this.$t('notifications.welcomeNotif'),
              type: 'success',
              // all of other options may go here
            });
            localStorage.setItem("algerieDok-d", response.data.user.dateOfBirth);
            this.$store.commit('SET_USER', response.data.user)
            this.$store.commit('SET_TOKEN', response.data.token)
            this.initSocket()
            // this.$store.commit('setUserInfo',response.data.user)
            this.$router.push({ name: 'Home' })
            // location.reload();
          })
          .catch((error) => {
            this.$loading(false)
            console.error("There was an error!", error);
            console.log(error.response)
            if (error.response.data.message == "ModelNotFound") {
              this.$toast.open({
                message: this.$t('notifications.accountDoesntExist'),
                type: 'error',
                duration: 7000
              });
            } else {
              this.$toast.open({
                message: error.response.data.details,
                type: 'error',
                // position: 'top-right'
                // all of other options may go here
              });
            }
          });
      })
    },
    addUser() {
      this.$loading(true)
      if (this.validateSignUpForm()) {
        console.log('wsel hnee')
        const login = new Promise((resolve, reject) => {
          axios
            .post(
              "https://algedoc-backend.herokuapp.com/api/user/signup",
              this.dataToSendSignUp
            )
            .then((response) => {
              this.$loading(false)
              this.$toast.open({
                message: this.$t('notifications.verificationEmailSent'),
                type: 'success',
                // position: 'top-right'
              });
              this.dataToSendSignUp.firstName = ""
              this.dataToSendSignUp.lastName = ""
              this.dataToSendSignUp.email = ""
              this.dataToSendSignUp.dateOfBirth = ""
              this.dataToSendSignUp.gender = "homme"
              this.dataToSendSignUp.phoneNumber = ""
              this.dataToSendSignUp.password = ""
              this.dataToSendSignUp.confirmPassword = ""
              this.dataToSendSignUp.role = "patient"
            })
            .catch((error) => {
              this.$loading(false)
              this.errorMessage = error.message;
              console.error("There was an error!", error);
              console.log("error : ", error.response)
              this.$toast.open({
                message: error.response.data.details,
                type: 'error',
                duration: 7000
                // position: 'top-right'
              });
            });
        })
      }

    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const res = re.test(String(email).toLowerCase())
      if (res) {
        this.IconSignUp.email = "border:1px solid #32a852";
      } else {
        this.IconSignUp.email = "border:1px solid #ff0010";
      }
      return res;
    },
    validateFirstName(firstName) {
      if (firstName.length > 0) {
        this.IconSignUp.firstName = "border:1px solid #32a852"
        return true
      }
      this.IconSignUp.firstName = "border:1px solid #ff0010"
      return false
    },
    validateLastName(lastName) {
      if (lastName.length > 0) {
        this.IconSignUp.lastName = "border:1px solid #32a852"
        return true
      }
      this.IconSignUp.lastName = "border:1px solid #ff0010"
      return false
    },
    validateDateOfBirth(dateOfBirth) {
      if (dateOfBirth.length > 0) {
        this.IconSignUp.dateOfBirth = "border:1px solid #32a852"
        return true
      }
      this.IconSignUp.dateOfBirth = "border:1px solid #ff0010"
      return false
    },
    validatePassword(password, confirmPassword) {
      if (password != confirmPassword) {
        this.IconSignUp.password = "border:1px solid #ff0010"
        this.IconSignUp.confirmPassword = "border:1px solid #ff0010"
        return false
      }
      if (password.length == 0) {
        this.IconSignUp.password = "border:1px solid #ff0010"
        this.IconSignUp.confirmPassword = "border:1px solid #ff0010"
        return false
      }
      this.IconSignUp.password = "border:1px solid #32a852"
      this.IconSignUp.confirmPassword = "border:1px solid #32a852"
      return true
    },
    validatePhoneNumber(phoneNumber) {
      if (phoneNumber.length > 7) {
        this.IconSignUp.phoneNumber = "border:1px solid #32a852";
        return true;
      }
      this.IconSignUp.phoneNumber = "border:1px solid #ff0010";
      return false;
    },
    validateSignUpForm() {
      console.log('hereeeee')
      let errorList = ''
      if (!this.validateFirstName(this.dataToSendSignUp.firstName)) {
        errorList += this.$t('attrs.surname') + ", ";
      }
      if (!this.validateLastName(this.dataToSendSignUp.lastName)) {
        errorList += this.$t('attrs.name') + ", ";
      }
      if (!this.validateDateOfBirth(this.dataToSendSignUp.dateOfBirth)) {
        errorList += this.$t('attrs.dateOfBirth') + ", ";
      }
      if (!this.validateEmail(this.dataToSendSignUp.email)) {
        errorList += 'Email, '
      }
      if (!this.validatePhoneNumber(this.dataToSendSignUp.phoneNumber)) {
        errorList += this.$t('attrs.phone') + ", ";
      }
      if (!this.validatePassword(this.dataToSendSignUp.password, this.dataToSendSignUp.confirmPassword)) {
        errorList += this.$t('patientsPage.links.password') + ", ";
      }
      console.log(errorList.length)
      if (errorList.length > 0) {
        this.$loading(false)
        this.$toast.open({
          message: this.$t('notifications.verifyFields') + errorList,
          type: 'error',
          duration: 10000
          // position: 'top-right'
        });
        return false
      }
      return true
    }
  },

}
