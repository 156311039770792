import axios from "axios";
export default {
  name: "profileInformationsSection",
  data() {
    return {
      userData: {},
      dateUserFormat: localStorage.getItem("algerieDok-d"),
      dateToChange: 0,
    };
  },
  created() {
    this.getUserData();
  },
  computed: {
    dateOfUser: {
      get() {
        var dateUser = new Date(this.dateUserFormat);
        var day = dateUser.getDate();
        if (day >= 0 && day <= 9) {
          day = "0" + day.toString();
        }
        var month = dateUser.getMonth() + 1;
        if (month >= 0 && month <= 9) {
          month = "0" + month.toString();
        }
        var years = dateUser.getFullYear();
        this.dateUserFormat = years + "-" + month + "-" + day;
        console.log(dateUser)
        return this.dateUserFormat;
      },
    },
  },
  methods: {
    getUserData() {
      axios
        .get(
          "https://algedoc-backend.herokuapp.com/api/patients/" +
          localStorage.getItem("algerieDok-U")
        )
        .then((response) => {
          this.userData = response.data;
          console.log(this.userData);
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    updateUserData() {
      this.dateToChange = 0;
      axios
        .put(
          "https://algedoc-backend.herokuapp.com/api/patients/" + localStorage.getItem("algerieDok-U"), this.userData
        )
        .then((response) => {
          this.userData = response.data
          this.$toast.open({
            message: this.$t('notifications.profileUpdated'),
            type: 'success',
            // all of other options may go here
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.$toast.open({
            message: this.$t('notifications.updateError'),
            type: 'error',
            duration: 7000
            // // position: 'top-right'
            // all of other options may go here
          });
        });
      // this.getUserData();
    },
    otherOption() {
      if ((this.userData.genre = "homme")) {
        return "femme";
      } else {
        return "homme";
      }
    },
  },
};
