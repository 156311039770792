export default {
  name: "slideInformation",
  props: ["slidePage"],

  data() {
    return {
      classAccount: "col-md-12 col-sm-6 col-xs-6 profile-menu-element",
      classRdv: "col-md-12 col-sm-6 col-xs-6 profile-menu-element",
      classPassword:"col-md-12 col-sm-6 col-xs-6 profile-menu-element",
      classChat:"col-md-12 col-sm-6 col-xs-6 profile-menu-element"
    };
  },
  computed:{
    user(){
      return this.$store.state.user
    }
  },
  mounted: function() {
    console.log(this.slidePage);
    if (this.slidePage == "userInfo") {
      this.classAccount = "col-md-12 col-sm-6 col-xs-6 profile-menu-element active";
      this.classRdv = "col-md-12 col-sm-6 col-xs-6 profile-menu-element";
      this.classPassword="col-md-12 col-sm-6 col-xs-6 profile-menu-element"
      this.classChat="col-md-12 col-sm-6 col-xs-6 profile-menu-element"
    } else if(this.slidePage == "userPassword") {
      this.classAccount = "col-md-12 col-sm-6 col-xs-6 profile-menu-element ";
      this.classRdv = "col-md-12 col-sm-6 col-xs-6 profile-menu-element";
      this.classPassword="col-md-12 col-sm-6 col-xs-6 profile-menu-element active"
      this.classChat="col-md-12 col-sm-6 col-xs-6 profile-menu-element"
    } else if(this.slidePage == "classChat") {
      this.classAccount = "col-md-12 col-sm-6 col-xs-6 profile-menu-element ";
      this.classRdv = "col-md-12 col-sm-6 col-xs-6 profile-menu-element";
      this.classPassword="col-md-12 col-sm-6 col-xs-6 profile-menu-element "
      this.classChat="col-md-12 col-sm-6 col-xs-6 profile-menu-element active"
    }else {
      this.classAccount = "col-md-12 col-sm-6 col-xs-6 profile-menu-element";
      this.classRdv = "col-md-12 col-sm-6 col-xs-6 profile-menu-element active";
      this.classPassword="col-md-12 col-sm-6 col-xs-6 profile-menu-element"
      this.classChat="col-md-12 col-sm-6 col-xs-6 profile-menu-element"
    }
  },
  methods:{
    signOut(){
      this.$store.dispatch('closeSocket')
      this.$loading(true)                
      setTimeout(() => {
        this.$loading(false)
        this.$store.commit('SET_USER',null)
        this.$store.commit('SET_TOKEN',null)
        this.$router.push({ name: 'Home' })
        this.$toast.open({
          message:this.$t('notifications.disconnected'),
          type: 'success',
          // all of other options may go here
        });
      },2000)
    }
  }
};
