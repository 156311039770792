import axios from "axios";
export default {
  name: "profilePasswordSection",
  data() {
    return {
      cpassword: null,
      password: null
    };
  },
  methods: {
    updateUserData() {
      var payload = {
        userId: localStorage.getItem("algerieDok-U"),
        cpassword: this.cpassword,
        password: this.password
      }
      axios
        .post(
          "https://algedoc-backend.herokuapp.com/api/user/changePassword", payload
        )
        .then((response) => {
          this.$toast.open({
            message: this.$t('notifications.profileUpdated'),
            type: 'success',
            duration: 5000
            // all of other options may go here
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.$toast.open({
            message: this.$t('notifications.updateError'),
            type: 'error',
            duration: 5000
            // // position: 'top-right'
            // all of other options may go here
          });
        });
      // this.getUserData();
    },
  },
};
