<template>
  <div id="app" :key="token">
    <router-view />
  </div>
</template>
<script>

export default {
  name: "App",
  data: () => ({
    socket: null,
    user:null
  }),
    computed: {
    async token() {
      if (this.$store.state.token) await this.getUser();
      return this.$store.state.token;
    },
  },
  methods: {
    async getUser() {
      if (this.$store.getters["isLoggedIn"]) {
        if (this.$store.state.role == "patient") {
          this.user = await this.$store.dispatch("getPatient");
        } else {
          this.user = await this.$store.dispatch("getDoctor");
        }
        this.initSocket()
      }
    },

    async initSocket() {
      this.$store.dispatch('connectSocket')
      this.socket = this.$store.state.socket;
      await new Promise((res, rej) => {
        let interval = setInterval(() => {
          if (!this.socket) this.socket = this.$store.state.socket;
          else {
            clearInterval(interval);
            res();
            console.log("socket init");
          }
        }, 500);
      });
      if(this.user.role == 'patient'){
        this.$store.state.socket.on("appointmentAccepted", (notif) => {
          this.$toast.open({
            message:this.$t('notifications.appointmentAccepted',{'firstName':notif.data.doctor.firstName,'lastName':notif.data.doctor.lastName}),
            type: "success",
            duration: 5000
            // position: "top-right",
          });
        });
        this.$store.state.socket.on("appointmentDeclined", (notif) => {
          this.$toast.open({
            message:this.$t('notifications.appointmentDeclined',{'firstName':notif.data.doctor.firstName,'lastName':notif.data.doctor.lastName}),
            type: "error",
            duration: 5000
            // position: "top-right",
          });
        });
      }
      else if(user.role == 'doctor')
      this.$store.state.socket.on("appointmentReceived", (notif) => {
        this.$toast.open({
          message:this.$t('notifications.appointmentReceived', { 'firstName': notif.data.user.firstName, 'lastName': notif.data.user.lastName }),
          type: "error",
          duration: 5000
          // position: "top-right",
        });
      });
    },
  },
  // async mounted() {
  //   await this.getUser()
  // },

  beforeDestroy(){
    this.$store.dispatch('socket/closeSocket')
  }
};
</script>
