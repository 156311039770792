import pubSection from "../../components/pubSection/index.vue";
import blogSection from "../../components/blogSection/index.vue";
import newsLetters from "../../components/newsLetters/index.vue";
import partenairesSection from "../../components/partenairesSection/index.vue";
import heroSection from "../../components/heroSection/index.vue";

export default {
  name: "blogPage",
  components: {
    heroSection,
    pubSection,
    blogSection,
    newsLetters,
    partenairesSection
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: 'Blog page',
      })
      this.$gtag.pageview({
        page_path: '/blog',
      })
    }
  }
};
