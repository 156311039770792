import axios from "axios";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";

export default {
  name: "searchSection",
  // props:["serach"],
  components: {
    Datepicker,
  },
  data() {
    return {
      map: null,
      dateToChange: 0,
      idGridTime: "",
      en: en,
      fr: fr,
      searchTypeDoc: "",
      optionFilter: false,
      searchOption: "",
      searchSexe: "",
      searchCity: "",
      searchValue: "",
      searchLang: "",
      userData: {},
      timeTable: [],
      userData: {},
      // isClicked: false,
      test: true,
      page2: true,
      finish: true,
      state: {
        date: new Date(),
        selectedDate: new Date(),
        disabledDates: {
          to: new Date(),
          days: [],
        },
      },
      doctorList: [],
      value: null,
      validated: true,
      autre: false,
      doctorId: "",
      filled: true,
      selectedTime: null,
      //doctorList:[],
      //value:null,
      selectedFilters: [],
      dataToFilter: [],
      sortedData: [],
      pageSize: 8,
      currentPage: 1,
      // doctorInfo:{}
      dateUserFormat: new Date(),
      // search1: this.$router.history.current.query.s1,
      // search2: this.$router.history.current.query.s2,
      allDoctorData: []
    };
  },
  computed: {
    specialitites() {
      return this.$t('specList')
    },
    dateOfUser: {
      get() {
        var dateUser = new Date(this.dateUserFormat);
        var day = dateUser.getDate();
        if (day >= 0 && day <= 9) {
          day = "0" + day.toString();
        }
        var month = dateUser.getMonth() + 1;
        if (month >= 0 && month <= 9) {
          month = "0" + month.toString();
        }
        var years = dateUser.getFullYear();
        this.dateUserFormat = years + "-" + month + "-" + day;
        return this.dateUserFormat;
      },
    },
    search1() {
      return this.$route.query.s1;
    },
    search2() {
      return this.$route.query.s2;
    },
    search3() {
      return this.$route.query.s3;
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newVal) {
        this.getAllDoctor();
      },
    },
  },
  async mounted() {
    setTimeout(() => {
      this.initMap();
    }, 1000);
  },
  methods: {
    confirmDate() {
      if (!this.selectedTime) {
        this.$toast.open({
          message: this.$t('notifications.selectTimeError'),
          type: "error",
          // position: "top-right",
          // all of other options may go here
        });
      } else {
        this.finish = !this.finish
        this.page2 = !this.page2
      }
    },
    async enterChat(id) {
      try {
        const chatRoom = await this.$store.dispatch('createRoom', id)
        console.log(chatRoom);
        await this.$router.push({ path: `/chat?roomId=${chatRoom._id}` })
      }
      catch (err) {
        console.log(err);
      }
    },
    doctorTime(time, type, doctor) {
      if (time == 7) {
        time = 0
      }
      var result = "-";
      if (doctor.workSchedule) {
        if (type == "morning") {
          doctor.workSchedule.forEach(function (element, index) {
            if ((element.morning.end != null) || (element.morning.start != null)) {
              if (element.day == time) {
                result = element.morning.start + "-" + element.morning.end;
              }
            }
          });
        }
        if (type == "evening") {
          doctor.workSchedule.forEach(function (element, index) {
            if ((element.evening.end != null) || (element.evening.start != null)) {
              if (element.day == time) {
                result = element.evening.start + "-" + element.evening.end;
              }
            }
          });
        }
      }
      return result;
    },
    doctorWorkTime(time, index) {
      var res = time.split("-")
      if (res[0] == 0) {
        return "-"
      }
      res[0] = "01/01/2021 " + res[0]
      var newMinute = new Date(res[0])
      newMinute.setMinutes(newMinute.getMinutes() + 30 * index)
      var hours = newMinute.getHours();
      return this.formatDate(newMinute.getHours()) + ":" + this.formatDate(newMinute.getMinutes())
    },
    initMap() {
      let position = null;
      if (this.sortedData[0].position)
        position = {
          lat: parseFloat(this.sortedData[0].position.lat),
          lng: parseFloat(this.sortedData[0].position.lng),
        };
      let myLatlng = position || {
        lat: 30.87587185903049,
        lng: 13.190554884293533,
      };
      const map = new google.maps.Map(document.getElementById("mapAllDoctor"), {
        zoom: 5,
        center: myLatlng,
      });
      var locations = [];
      this.allDoctorData.forEach(element => {
        var location = []
        if (element.position != undefined) {
          location.push(element.firstName + " " + element.lastName)
          location.push(element.position.lat)
          location.push(element.position.lng)
          locations.push(location)
        }
      });
      var infowindow = new google.maps.InfoWindow();
      var marker, i;

      for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
          position: new google.maps.LatLng(locations[i][1], locations[i][2]),
          map: map
        });

        google.maps.event.addListener(marker, 'click', (function (marker, i) {
          return function () {
            infowindow.setContent(locations[i][0]);
            infowindow.open(map, marker);
          }
        })(marker, i));
      }
    },
    testLoginUser() {
      if (
        localStorage.getItem("algerieDok-U") &&
        localStorage.getItem("algerieDok-R") != "doctor"
      ) {
        return true;
      } else {
        return false;
      }
    },
    notifUser() {
      if (localStorage.getItem("algerieDok-R") != "doctor") {
        this.$toast.open({
          message: this.$t('notifications.notConnectedError'),
          type: "info",
          // position: "top-right",
          // all of other options may go here
        });
      } else {
        this.$toast.open({
          message: this.$t('notifications.notPatientError'),
          type: "info",
          duration: 5000
          // position: "top-right",
          // all of other options may go here
        });
      }
    },
    getDate(date) {
      return new Date(date).toLocaleDateString();
    },
    cleanInputs() {
      this.searchTypeDoc = ""
      this.searchOption = "";
      this.searchValue = "";
      this.searchSexe = "";
      this.searchLang = "";
      this.searchCity = "";
      this.$toast.open({
        message: this.$t('notifications.filtersDeleted'),
        type: "success",
        // position: "top-right",
      });
    },
    deleteFilter() {
      this.selectedFilters = [];
      this.$router.history.current.query.s1 = "null"
      this.$router.history.current.query.s2 = "null"
      this.$router.history.current.query.s3 = "null"
      this.getAllDoctor();
    },
    getAllDoctor() {
      console.log(this.$router.history.current.query.s1);
      axios
        .get("https://algedoc-backend.herokuapp.com/api/doctors/")
        .then((response) => {
          console.log("doctor", response);
          this.allDoctorData = response.data;
          this.doctorList = response.data.filter((element) => {
            if (element.doctorAdminValidation) {
              return element;
            }
          });
          this.dataToFilter = response.data.filter((element) => {
            if (element.doctorAdminValidation) {
              return element;
            }
          });
          if (
            this.$router.history.current.query.s1 != "null" &&
            this.$router.history.current.query.s2 != "null" &&
            this.$router.history.current.query.s3 != "null"
          ) {
            this.searchResult("search1");
            this.searchResult("search2");
            this.searchResult("search3");
          } else if (this.$router.history.current.query.s1 != "null") {
            this.searchResult("search1");
          } else if (this.$router.history.current.query.s2 != "null") {
            this.searchResult("search2");
          } else if (this.$router.history.current.query.s3 != "null") {
            this.searchResult("search3");
          } else {
            this.searchResult();
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    dateSelected() {
      this.idGridTime = "time";
      this.timeTable = [];
      var dateToSplite = "";
      var doctorInfo = this.doctorList.find(
        (element) => element._id == this.doctorId
      );
      var day = this.state.selectedDate.getDay();
      doctorInfo.workSchedule.forEach((infoDoc) => {
        if (day == infoDoc.day) {
          if (infoDoc.morning.start != null && infoDoc.morning.end != null) {
            dateToSplite = "01/01/2021 " + infoDoc.morning.start + ":00";
            var dt = new Date(dateToSplite);
            do {
              var hours = dt.getHours();
              hours = this.formatDate(hours);
              var minute = dt.getMinutes();
              minute = this.formatDate(minute);
              this.timeTable.push(hours + ":" + minute);
              dt.setMinutes(dt.getMinutes() + 30);
            } while (hours < infoDoc.morning.end.split(":")[0]);
          }
          if (infoDoc.evening.start != null && infoDoc.evening.end != null) {
            dateToSplite = "01/01/2021 " + infoDoc.evening.start + ":00";
            var dt = new Date(dateToSplite);
            do {
              var hours = dt.getHours();
              hours = this.formatDate(hours);
              var minute = dt.getMinutes();
              minute = this.formatDate(minute);
              this.timeTable.push(hours + ":" + minute);
              dt.setMinutes(dt.getMinutes() + 30);
            } while (hours < infoDoc.evening.end.split(":")[0]);
          }
        }
      });
    },
    formatDate(value) {
      if (value >= 0 && value <= 9) {
        value = "0" + value.toString();
      }
      return value;
    },
    disabledDates() {
      var doctorInfo = this.doctorList.find(
        (element) => element._id == this.doctorId
      );
      var days = [0, 1, 2, 3, 4, 5, 6];
      Object.values(doctorInfo.workSchedule).forEach((val) => {
        days = days.filter(function (item) {
          return item !== val.day;
        });
      });
      this.state.disabledDates.days = days;
    },
    saveNewData() {
      this.dateToChange = 0;
      var payload = {
        lastName: this.userData.lastName,
        firstName: this.userData.firstName,
        email: this.userData.email,
        phoneNumber: this.userData.phoneNumber,
        gender: this.userData.gender,
        dateOfBirth: this.userData.dateOfBirth,
      };
      axios
        .put(
          "https://algedoc-backend.herokuapp.com/api/patients/" +
          localStorage.getItem("algerieDok-U"),
          payload
        )
        .then((response) => { })
        .catch((error) => {
          console.log("modifier utilisateur rdv");
          console.log(error);
        });
    },
    autrePersonne() {
      this.userData.lastName = "";
      this.userData.firstName = "";
      this.userData.email = "";
      this.userData.phoneNumber = "";
      this.userData.gender = "";
      this.userData.dateOfBirth = "";
    },
    doctorSpe(spe) {
      var result = "";
    },
    nextPage: function () {
      if (this.currentPage * this.pageSize < this.doctorList.length) {
        this.currentPage++;
      }
      this.sortedElement();
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--;
      this.sortedElement();
    },
    sortedElement() {
      this.sortedData = this.dataToFilter.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return row;
      });
    },
    searchResult(MethodNameFilter) {
      console.log(MethodNameFilter);
      var dataFilterData = this.doctorList;
      if (this.selectedFilters.includes(MethodNameFilter)) {
        this.selectedFilters.splice(
          this.selectedFilters.indexOf(MethodNameFilter),
          1
        );
      }
      if (MethodNameFilter != undefined) {
        this.selectedFilters.push(MethodNameFilter);
      }
      this.selectedFilters.forEach((element) => {
        if (element === "optionTypeDoc") {
          dataFilterData = this.optionTypeDoc(dataFilterData);
        }
        if (element === "search1") {
          dataFilterData = this.filterSearch1(dataFilterData);
        }
        if (element === "search2") {
          dataFilterData = this.filterSearch2(dataFilterData);
        }
        if (element === "search3") {
          dataFilterData = this.filterSearch3(dataFilterData);
        }
        if (element === "optionSpe") {
          dataFilterData = this.filterSpe(dataFilterData);
        }
        if (element === "optionSexe") {
          dataFilterData = this.filterSexe(dataFilterData);
        }
        if (element === "optionCity") {
          dataFilterData = this.filterCity(dataFilterData);
        }
        if (element === "value") {
          dataFilterData = this.filterName(dataFilterData);
        }
        if (element === "optionLang") {
          dataFilterData = this.filterLang(dataFilterData);
        }
      });
      dataFilterData.sort((a, b) => b.offer - a.offer);
      this.sortedElement();
    },
    optionTypeDoc: function (dataFilterData) {
      this.dataToFilter = dataFilterData.filter((element) => {
        // element.consultationType.forEach(type => {
        //   if(type==this.searchTypeDoc){
        //     return element
        //   }
        // });
        if (this.searchTypeDoc == "") {
          return element;
        } else if ((element.consultationType[0] == this.searchTypeDoc) || (element.consultationType[1] == this.searchTypeDoc)) {
          return element
        }
      });
      return this.dataToFilter;
    },
    filterSearch1: function (dataFilterData) {
      var valueToSerach = "";
      var allSpe = "";
      this.dataToFilter = dataFilterData.filter((element) => {
        allSpe = element.specialty.toString();
        valueToSerach = element.lastName + " " + element.firstName + allSpe;
        return this.search1
          .toLowerCase()
          .split(" ")
          .every((v) => valueToSerach.toLowerCase().includes(v));
      });
      return this.dataToFilter;
    },
    filterSearch2: function (dataFilterData) {
      var allCity = "";
      var allAdresse = "";
      var valueToSerach = "";
      this.dataToFilter = dataFilterData.filter((element) => {
        if (element.city) {
          allCity = element.city.toString();
        }
        if (element.address) {
          allAdresse = element.address.toString();
        }
        valueToSerach = allAdresse + " " + allCity;
        return this.search2
          .toLowerCase()
          .split(" ")
          .every((v) => valueToSerach.toLowerCase().includes(v));
      });
      return this.dataToFilter;
    },
    filterSearch3: function (dataFilterData) {
      var allCity = "";
      // var allAdresse = "";
      // var valueToSerach = "";
      this.dataToFilter = dataFilterData.filter((element) => {
        if (element.city) {
          allCity = element.city.toString();
        }
        // if (element.address) {
        //   allAdresse = element.address.toString();
        // }
        // valueToSerach = allAdresse + " " + allCity;
        return this.search3
          .toLowerCase()
          .split(" ")
          .every((v) => allCity.toLowerCase().includes(v));
      });
      return this.dataToFilter;
    },
    filterSpe: function (dataFilterData) {
      this.dataToFilter = dataFilterData.filter((element) => {
        if (this.searchOption === "") {
          return element;
        } else {
          return element.specialty.find((spe) => spe == this.searchOption);
        }
      });
      return this.dataToFilter;
    },
    filterLang: function (dataFilterData) {
      this.dataToFilter = dataFilterData.filter((element) => {
        if (this.searchLang == "") {
          return element;
        } else {
          return element.spokenLanguage.find((spe) => spe == this.searchLang);
        }
      });
      return this.dataToFilter;
    },
    filterSexe: function (dataFilterData) {
      this.dataToFilter = dataFilterData.filter((element) => {
        if (this.searchSexe == "") {
          return element;
        } else {
          return element.gender == this.searchSexe;
        }
      });
      return this.dataToFilter;
    },
    filterCity: function (dataFilterData) {
      this.dataToFilter = dataFilterData.filter((element) => {
        if (this.searchCity == "") {
          return element;
        } else {
          return element.city == this.searchCity;
        }
      });
      return this.dataToFilter;
    },
    filterName: function (dataFilterData) {
      var name = "";
      this.dataToFilter = dataFilterData.filter((element) => {
        if (this.searchValue === "") {
          return element;
        } else {
          name = element.lastName + " " + element.firstName;
          return this.searchValue
            .toLowerCase()
            .split(" ")
            .every((v) => name.toLowerCase().includes(v));
        }
      });
      return this.dataToFilter;
    },
    doctorImg(element) {
      if (!element.photo) {
        if (element.gender == "homme") {
          return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611628277/t%C3%A9l%C3%A9chargement_yo46pz.jpg";
        } else if (element.gender == "femme") {
          return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611690556/2ac5343c-2653-4e20-862a-6205d4e6fa61_lict1f.png";
        }
      } else {
        return (
          "https://res.cloudinary.com/dba2dcapc/image/upload/v1611178444/" +
          element.photo
        );
      }
    },
    doctorSpe(spe) {
      return spe.map(spec => this.$t(`specList[${spec}]`)).join(', ')
      // var result = "";
      // spe.forEach((element) => {
      //   result = element + ", " + result;
      // });
      // return result;
    },
    doctorAddress(element) {
      if (!element.city && !element.address) {
        return "inexistant";
      } else if (!element.city) {
        return element.address;
      } else if (!element.address) {
        return element.city;
      } else {
        return element.address + ", " + element.city;
      }
    },
    doctorDays(days) {
      var result = "";
      days.forEach((element) => {
        switch (element.day) {
          case 1:
            result = result + "lun" + ", ";
            break;
          case 2:
            result = result + "Mar" + ", ";
            break;
          case 3:
            result = result + "Mer" + ", ";
            break;
          case 4:
            result = result + "Jeu" + ", ";
            break;
          case 5:
            result = result + "Ven" + ", ";
            break;
          case 6:
            result = result + "Sam" + ", ";
            break;
          case 0:
            result = result + "Dim" + ", ";
            break;
        }
      });
      return result;
    },
    doctorTimes(times) {
      var result = "";
      times.forEach((element) => {
        result = element.start + "-" + element.end;
      });
      return result;
    },
    doctorLink(id) {
      return "/doctor-info?docId=" + id;
    },
    showPatientData(element) {
      this.selectedTime = null
      this.finish = true;
      this.page2 = true;
      this.idGridTime = "";
      this.timeTable = [];
      this.test = true;
      this.doctorId = element;
      axios
        .get(
          "https://algedoc-backend.herokuapp.com/api/patients/" +
          localStorage.getItem("algerieDok-U")
        )
        .then((response) => {
          this.userData = response.data;
          // this.disabledDates
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    checkTime(time) {
      this.selectedTime = time;
    },
    checkDay(day) {
      this.selectedDay = day;
    },
    createAppointment() {
      if (this.selectedTime || this.userData.phoneNumber == "") {
        if (this.autre) {
          var payload = {
            patientId: localStorage.getItem("algerieDok-U"),
            docId: this.doctorId,
            guest: {
              lastName: this.userData.lastName,
              firstName: this.userData.firstName,
              email: this.userData.email,
              phoneNumber: this.userData.phoneNumber,
              gender: this.userData.gender,
              dateOfBirth: this.userData.dateOfBirth,
            },
            date: {
              time: this.selectedTime,
              day: this.state.selectedDate,
            },
          };
        } else {
          var payload = {
            patientId: localStorage.getItem("algerieDok-U"),
            docId: this.doctorId,
            date: {
              time: this.selectedTime,
              day: this.state.selectedDate,
            },
          };
        }
        console.log(payload, this.autre);
        axios
          .post(
            "https://algedoc-backend.herokuapp.com/api/appointments/",
            payload
          )
          .then((response) => {
            this.userData = response.data;
            // this.disabledDates
            this.$toast.open({
              message: this.$t('notifications.appointmentCreated'),
              type: "success",
              duration: 8000
              // position: "top-right",
            });
            this.$store.dispatch("sendAppointmentNotification", payload.docId);
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.log("error date", error)
            this.$toast.open({
              message: this.$t('notifications.appointmentError'),
              type: "error",
              duration: 7000
              // position: "top-right",
            });
          });
      } else {
        this.$toast.open({
          message: this.$t('notifications.appointmentError'),
          type: "error",
          duration: 7000
          // position: "top-right",
        });
      }
    },
  },
};
