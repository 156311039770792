import { Carousel, Slide } from "vue-carousel";
import axios from "axios";
export default {
  name: "partenairesSection",
  components: {
    Carousel,
    Slide
  },
  data: () => {
    return {
      partnersData: []
    }
  },
  created() {
    axios
      .get(
        "https://algedoc-backend.herokuapp.com/api/partners"
      )
      .then((response) => {
        this.partnersData = response.data.partners
      })
      .catch((error) => {
        console.log("partenaire")
        this.errorMessage = error.message;
      });
  },
  methods: {
    partnersImg(img) {
      return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611354830/" + img
    }
  }

};
