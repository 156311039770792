import heroSection from "../../components/heroSection/index.vue";
import specList from "../../components/specList/index.vue";

export default {
  name: "specListPage",
  components: {
    heroSection,
    specList
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: 'Specialities page',
      })
      this.$gtag.pageview({
        page_path: '/specialities',
      })
    }
  }
};
