<template>
  <div class="chatPage">
    <heroSection typePage="subHero"></heroSection>
    <div class="container">
      <div class="row user-profile-page">
        <div class="col-md-3">
          <slideInformation slidePage="classChat"></slideInformation>
        </div>
        <div class="col-md-9">
          <chat-window
            v-if="user"
            :current-user-id="user._id"
            :rooms="rooms"
            :messages="messages"
            @send-message="sendMessage"
            @fetch-messages="getRoomInfo"
            :loading-rooms="loadingRooms"
            :rooms-loaded="true"
            :room-id="$route.query.roomId"
            :messages-loaded="messagesloaded"
            @open-file="openFile"
            @send-message-reaction="sendReaction"
            @edit-message="editMessage"
            @delete-message="deleteMessage"
          />
          <div
            class="modal fade"
            id="fileModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            style="text-align: center; margin-top: 10vh"
          >
            <img
              :src="activeImage"
              style="max-height: 80vh; background-color: white"
            />
          </div>
          <button
            data-toggle="modal"
            data-target="#fileModal"
            style="display: none"
            id="modalBtn"
          ></button>
        </div>
      </div>
    </div>
    <newsLetters></newsLetters>
    <partenairesSection></partenairesSection>
    <!-- probleme espace a droite -->
  </div>
</template>

<script>
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import heroSection from "../../components/heroSection/index.vue";
import slideInformation from "../../components/slideInformation/index.vue";
import axios from "axios";
export default {
  components: {
    ChatWindow,
    heroSection,
    slideInformation,
  },
  data() {
    return {
      rooms: [],
      messages: [],
      messagesloaded: false,
      roomId: null,
      otherUser: null,
      loadingRooms: true,
      showModal: false,
      activeImage: "",
      interval: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: "chat",
      });
      this.$gtag.pageview({
        page_path: "/chat",
      });
    },
    getRoomInfo({ room }) {
      this.roomId = room.roomId;
      this.messagesloaded = false;
      this.getMessages(room.roomId);
    },

    async getMessages(roomId) {
      try {
        if (roomId) {
          this.messages = await this.$store.dispatch("getMessages", roomId);
          this.messagesloaded = true;
        }
      } catch (error) {
        console.log(error);
        this.messagesloaded = false;
      }
    },

    async getRooms() {
      try {
        const rooms = await this.$store.dispatch("getChatRooms");
        this.loadingRooms = false;
        this.rooms = rooms.map((room) => ({
          ...room,
          users: this.getRoomUsers(room),
          roomId: room._id,
          roomName: this.getRoomName(room),
          avatar: this.getOtherUserAvatar(room),
        }));
      } catch (error) {
        console.log(error);
      }
    },

    getOtherUser(users) {
      console.log(users);
      return users.find((user) => user._id != this.$store.state.userId);
    },

    getOtherUserAvatar({ users }) {
      const otherUser = this.getOtherUser(users);
      return !otherUser.photo
        ? "https://res.cloudinary.com/dba2dcapc/image/upload/v1611628277/t%C3%A9l%C3%A9chargement_yo46pz.jpg"
        : `${this.$store.state.cloudinaryUrl}${otherUser.photo}`;
    },

    getRoomName({ users }) {
      const otherUser = this.getOtherUser(users);
      return `${otherUser.firstName || ""} ${otherUser.lastName || ""}`;
    },

    getRoomUsers({ users }) {
      return users.map((user) => ({
        username: `${user.firstName} ${user.lastName}`,
        ...user,
      }));
    },

    async sendMessage(message) {
      try {
        message.sender_id = this.user._id;
        if (message.file) {
          message.file.url = await this.uploadFile(message.file);
        }
        const { messageToSend } = await this.$store.dispatch(
          "sendMessage",
          message
        );
        console.log(messageToSend);
        this.messages = [...this.messages, messageToSend];
      } catch (err) {
        console.log(err);
      }
    },

    async sendReaction({ roomId, messageId, reaction, remove }) {
      let messageIndex = this.messages.findIndex(
        (message) => message._id == messageId
      );
      let message = this.messages[messageIndex];
      message.reactions = message.reactions
        ? { ...message.reactions, [reaction.name]: [this.user._id] }
        : { [reaction.name]: [this.user._id] };
      try {
        await this.$store.dispatch("sendReaction", {
          messageId,
          reactions: message.reactions,
        });
      } catch (err) {
        console.log(err);
      }
      this.messages[messageIndex] = message;
      this.messages = [...this.messages];
      console.log(this.messages);
    },

    async editMessage({ messageId, newContent }) {
      try {
        const message = await this.$store.dispatch("updateMessage", {
          messageId,
          message: { content: newContent },
        });
        this.editLocalMessage(messageId, newContent);
      } catch (err) {
        console.log(err);
      }
    },

    editLocalMessage(messageId, newContent) {
      let messageIndex = this.messages.findIndex(
        (message) => message._id == messageId
      );
      let message = this.messages[messageIndex];
      message.content = newContent;
      this.messages[messageIndex] = message;
      this.messages = [...this.messages];
    },

    async deleteMessage({ messageId }) {
      try {
        const res = await this.$store.dispatch("deleteMessage", {
          messageId,
        });
        this.deleteLocalMessage(messageId);
      } catch (err) {
        console.log(err);
      }
    },

    deleteLocalMessage(messageId) {
      let index = this.messages.findIndex(
        (message) => message._id.toString() == messageId
      );
      this.messages.splice(index, 1);
      this.messages = [...this.messages];
    },

    async uploadFile(file) {
      const formData = new FormData();
      formData.append("file", file.blob);
      formData.append("upload_preset", "upzzyy75");
      return await axios
        .post(`https://api.cloudinary.com/v1_1/dba2dcapc/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return res.data.secure_url;
        })
        .catch((err) => console.log(err));
    },

    openFile({ message, action }) {
      switch (action) {
        case "preview":
          // const win = window.open(message.file.url, "_blank");
          // win.focus();
          // this.showModal = true;
          document.getElementById("modalBtn").click();
          this.activeImage = message.file.url;
          break;
        case "download":
          axios
            .get(message.file.url, {
              responseType: "blob",
            })
            .then((res) => {
              const url = window.URL.createObjectURL(res.data);
              const a = document.createElement("a");
              a.href = url;
              a.download = message.file.name;
              a.click();
            })
            .catch((err) => console.log(err));
      }
    },
  },
  async mounted() {
    await this.getRooms();
    this.interval = setInterval(() => {
      this.getMessages(this.roomId);
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style>
.vac-add-icon {
  display: none;
}
.vac-room-header {
  z-index: 0 !important;
}
</style>