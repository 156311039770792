import pubSection from "../../components/pubSection/index.vue";
import searchSection from "../../components/searchSection/index.vue";
import newsLetters from "../../components/newsLetters/index.vue";
import partenairesSection from "../../components/partenairesSection/index.vue";
import heroSection from "../../components/heroSection/index.vue";

export default {
  name: "searchPage",
  components: {
    heroSection,
    pubSection,
    searchSection,
    newsLetters,
    partenairesSection
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: 'Search page',
      })
      this.$gtag.pageview({
        page_path: '/search',
      })
    }
  }
};
