import userCalendarSection from "../../components/userCalendarSection/index.vue";
import newsLetters from "../../components/newsLetters/index.vue";
import partenairesSection from "../../components/partenairesSection/index.vue";
import heroSection from "../../components/heroSection/index.vue";
import slideInformation from "../../components/slideInformation/index.vue";

export default {
  name: "patientPage",
  components: {
    slideInformation,
    heroSection,
    userCalendarSection,
    newsLetters,
    partenairesSection
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: 'Patient page',
      })
      this.$gtag.pageview({
        page_path: '/patient',
      })
    }
  }
};
