export default {
  name: "navbarSection",
  data() {
    const lang = localStorage.getItem('lang') || 'fr'
    return {
      langImages: [
        'lang-fr.png',
        'lang-en.jpg',
        'lang-tr.svg'
      ],
      languages: ['fr', 'en', 'tr'],
      lang,
    }
  },
  created() {
    if (this.$route.query.isVerified == "true") {
      this.$toast.open({
        message: this.$t('notifications.accountVerified'),
        type: 'success',
        duration: 7000
        // all of other options may go here
      });
    }
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.commit('SET_LANGUAGE', lang)
      // console.log(this.$store.state.locale);
    },
    dashboardLink() {
      // return "http://localhost:8081/#/dashboard?docId="+ this.user._id
      // return "http://algeriedok.com/doctor?docId=" + this.user._id
      return "/doctor"
    },
    async logout() {
      this.$loading(true)
      setTimeout(() => {
        this.$loading(false)
        this.$store.dispatch('closeSocket')
        this.$store.commit('SET_USER', null)
        this.$store.commit('SET_TOKEN', null)
        this.$router.push({ name: 'Home' })
        this.$toast.open({
          message: this.$t('notifications.disconnected'),
          type: 'success',
          // all of other options may go here
        });
      }, 2000)


    },

    async getNotifications() {
      console.log("getting notifications");
      await this.$store.dispatch('getNotifications')
    },

    sortByDate(array) {
      return array.sort((a, b) => {
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)
        return dateB - dateA
      })
    },

    async openNotif(notification) {
      try {
        if (this.user.role == "patient")
          await this.$router.replace('/patient')
        else if (this.user.role == "doctor")
          window.location.href = this.dashboardLink() + `&notifId=${notification._id}`
      }
      catch (err) {
        console.log(err);
      }
      if (!notification.seen) {
        try {
          const index = this.notifications.findIndex(notif => notif._id == notification._id)
          const updatedNotif = await this.$store.dispatch('updateNotification', notification._id)
          this.notifications.splice(index, 1, updatedNotif)
          this.$store.commit('SET_NOTIFS', this.notifications)
        }
        catch (err) {
          console.log(err);
        }
      }
    },

    getNotif(notif) {
      switch (notif.type) {
        case "appointmentAccepted":
          return {
            ...notif,
            title: this.$t('notifications.appointmentAcceptedTitle'),
            description: this.$t('notifications.appointmentAccepted', { 'firstName': notif.data.doctor.firstName, 'lastName': notif.data.doctor.lastName })
          }
        case "appointmentDeclined":
          return {
            ...notif,
            title: this.$t('notifications.appointmentDeclinedTitle'),
            description: this.$t('notifications.appointmentDeclined', { 'firstName': notif.data.doctor.firstName, 'lastName': notif.data.doctor.lastName })
          }
        case "welcome":
          return {
            ...notif,
            title: this.$t('notifications.welcomeNotif'),
            description: this.$t('notifications.welcome', { 'firstName': notif.data.newUser.firstName, 'lastName': notif.data.newUser.lastName })
          }
        case "appointmentReceived":
          return {
            ...notif,
            title: this.$t('notifications.appointmentReceivedTitle'),
            description: this.$t('notifications.appointmentReceived', { 'firstName': notif.data.user.firstName, 'lastName': notif.data.user.lastName })
          }
      }
    }

  },
  computed: {
    user() {
      const user = this.$store.state.user
      if (user)
        this.getNotifications()
      return user
    },
    isHome() {
      return this.$route.path == '/'
    },
    loggedIn() {
      return this.$store.getters.isLoggedIn
    },

    notifications() {
      console.log(this.$store.state.notifications);
      return this.sortByDate(this.$store.state.notifications).map(notif => this.getNotif(notif))
    },

    notificationCount() {
      return this.notifications.reduce((acc, cur) => !cur.seen ? acc + 1 : acc, 0)
    }
  },
};
