import newsLetters from "../../components/newsLetters/index.vue";
import partenairesSection from "../../components/partenairesSection/index.vue";
import heroSection from "../../components/heroSection/index.vue";
import slideInformation from "../../components/slideInformation/index.vue";
import profilePasswordSection from "../../components/profilePasswordSection/index.vue";

export default {
  name: "updatePasswordPage",
  components: {
    slideInformation,
    heroSection,
    profilePasswordSection,
    newsLetters,
    partenairesSection
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: 'Update password page',
      })
      this.$gtag.pageview({
        page_path: '/updatepassword',
      })
    }
  }
};
