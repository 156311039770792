import axios from "axios";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
export default {
  name: "userCalendarSection",
  components: {
    // SingleDatePicker,
    Datepicker,
  },
  data: () => {
    return {
      idGridTime: "",
      userAppointmentsDataOld: [],
      userAppointmentsDataNew: [],
      userAppointmentsDataDelete: [],
      state: {
        date: new Date(),
        selectedDate: new Date(),
        disabledDates: {
          to: new Date(),
          days: [],
        },
      },
      idAppointments: "",
      en: en,
      fr: fr,
      timeTable: [],
      doctorInfo: {},
      selectedTime: null,
      stateStyle: "text-center state-style",
      idAppointmentToCancel: ""
    };
  },

  created() {
    this.getAllAppointement();
  },
  methods: {
    getLink(id) {
      return '/doctor-info?docId=' + id
    },
    speDisplay(tabSpe) {
      return tabSpe.map(spec => this.$t(`specList[${spec}]`)).join(', ')
    },
    stateDoctor(state) {
      if (state == "pending") {
        return "En cours";
      } else if (state == "accepted") {
        return "Accepté";
      }
    },

    getStateStyle(state) {
      switch (state) {
        case "declined":
          return "state-style-declined"
        case "accepted":
          return "state-style-accepted"
      }
    },
    checkTime(time) {
      this.selectedTime = time;
    },
    getDocData(element, id) {
      this.idAppointments = id;
      this.doctorInfo = element;
      this.doctorInfo = element;
      this.dateSelected();
      this.disabledDates();
    },
    dateSelected() {
      this.idGridTime = "time";
      this.timeTable = [];
      var dateToSplite = "";
      var day = this.state.selectedDate.getDay();
      this.doctorInfo.workSchedule.forEach((infoDoc) => {
        if (day == infoDoc.day) {
          if (infoDoc.morning.start != null && infoDoc.morning.end != null) {
            dateToSplite = "01/01/2021 " + infoDoc.morning.start + ":00";
            var dt = new Date(dateToSplite);
            do {
              var hours = dt.getHours();
              hours = this.formatDate(hours);
              var minute = dt.getMinutes();
              minute = this.formatDate(minute);
              this.timeTable.push(hours + ":" + minute);
              dt.setMinutes(dt.getMinutes() + 30);
            } while (hours < infoDoc.morning.end.split(":")[0]);
          }
          if (infoDoc.evening.start != null && infoDoc.evening.end != null) {
            dateToSplite = "01/01/2021 " + infoDoc.evening.start + ":00";
            var dt = new Date(dateToSplite);
            do {
              var hours = dt.getHours();
              hours = this.formatDate(hours);
              var minute = dt.getMinutes();
              minute = this.formatDate(minute);
              this.timeTable.push(hours + ":" + minute);
              dt.setMinutes(dt.getMinutes() + 30);
            } while (hours < infoDoc.evening.end.split(":")[0]);
          }
        }
      });
    },
    formatDate(value) {
      if (value >= 0 && value <= 9) {
        value = "0" + value.toString();
      }
      return value;
    },
    disabledDates() {
      // axios
      //   .get(
      //     "https://algedoc-backend.herokuapp.com/api/doctors/" + this.doctorId,
      //   )
      //   .then((response) => {
      //     var doctor = response.data
      // var  doctorInfo=  element
      var days = [0, 1, 2, 3, 4, 5, 6];
      Object.values(this.doctorInfo.workSchedule).forEach((val) => {
        days = days.filter(function (item) {
          return item !== val.day;
        });
      });
      this.state.disabledDates.days = days;
      // })
      // .catch((error) => {
      //   this.errorMessage = error.message;
      // });
    },
    getAllAppointement() {
      this.userAppointmentsDataNew = [];
      this.userAppointmentsDataOld = [];
      axios
        .get(
          "https://algedoc-backend.herokuapp.com/api/appointments/patient/" +
          localStorage.getItem("algerieDok-U")
        )
        .then((response) => {
          console.log("rdv", response);
          response.data.forEach((element) => {
            if ((element.state != "canceled") && (element.state != "declined")) {
              if (new Date(element.date.day) >= new Date()) {
                this.userAppointmentsDataNew.push(element);
              } else {
                this.userAppointmentsDataOld.push(element);
              }
            } else {
              this.userAppointmentsDataDelete.push(element);
            }
          });
          console.log(
            this.userAppointmentsDataNew,
            this.userAppointmentsDataOld
          );
          // this.userAppointmentsData=response.data
        })
        .catch((error) => {
          console.log("blog rdv");
          this.errorMessage = error.message;
        });
    },
    dateForm(date) {
      var dateBlog = new Date(date);
      var day = dateBlog.getDate();
      var month = dateBlog.getMonth() + 1;
      var years = dateBlog.getFullYear();
      return years + "-" + this.filterDate(month) + "-" + this.filterDate(day);
    },
    filterDate(valueToChange) {
      if (valueToChange >= 0 && valueToChange < 9) {
        valueToChange = "0" + valueToChange.toString();
      }
      return valueToChange;
    },
    doctorImg(doctor) {
      if (!doctor.photo) {
        if (doctor.gender == "homme") {
          return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611628277/t%C3%A9l%C3%A9chargement_yo46pz.jpg"
        } else if (doctor.gender == "femme") {
          return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611690556/2ac5343c-2653-4e20-862a-6205d4e6fa61_lict1f.png"
        }
      } else {
        return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611178444/" + doctor.photo
      }
    },
    cancelAppointmentId(id) {
      this.idAppointmentToCancel = id
    },
    cancelAppointment() {
      axios
        .put("https://algedoc-backend.herokuapp.com/api/appointments/cancel/" + this.idAppointmentToCancel)
        .then((response) => {
          console.log(response);
          this.getAllAppointement();
          this.$toast.open({
            message: this.$t('notifications.appointmentCanceled'),
            type: "success",
            duration: 7000
          });
        })
        .catch((error) => {
          console.log(" rdv");
          this.errorMessage = error.message;
          this.$toast.open({
            message: this.$t('notifications.cancelError'),
            type: "error",
            duration: 7000
          });
        });
    },
    createAppointment() {
      if (this.state.selectedDate != null) {
        var payload = {
          date: {
            time: this.selectedTime,
            day: this.state.selectedDate,
          },
        };
        axios
          .put(
            "https://algedoc-backend.herokuapp.com/api/appointments/" +
            this.idAppointments,
            payload
          )
          .then((response) => {
            console.log(response);
            // this.disabledDates
            this.$toast.open({
              message: this.$t('notifications.appointmentCreated'),
              type: "success",
              duration: 7000
              // position: "top-right",
            });
            // this.$store.dispatch('sendAppointmentNotification',response.data.docId)
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.$toast.open({
              message: this.$t('notifications.appointmentError'),
              type: "error",
              duration: 7000
              // position: "top-right",
            });
          });
        setTimeout(() => {
          this.getAllAppointement();
        }, 1000);
      } else {
        this.$toast.open({
          message: this.$t('notifications.verifyData'),
          type: "error",
          // position: "top-right",
        });
      }
    },
  },
};
