import { Carousel, Slide } from "vue-carousel";
import axios from "axios";


export default {
  name: "heroSection",
  props: ["typePage"],
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      search1: null,
      search2: null,
      search3: null,
      search4: null,
      searchStyle1: "NoDisplay",
      searchStyle2: "NoDisplay",
      classHeader: "",
      allDoctorData: [],
      doctorList: [],
      doctorList2: ["Adrar"
        , "Aïn Defla"
        , "Aïn Témouchent"
        , "Alger"
        , "Annaba"
        , "Batna"
        , "Béchar"
        , "Béjaïa"
        , "Biskra"
        , "Blida"
        , "Bordj Bou Arréridj"
        , "Bouira"
        , "Boumerdès"
        , "Chlef"
        , "Constantine"
        , "Djelfa"
        , "El Bayadh"
        , "El Oued"
        , "El Tarf"
        , "Ghardaïa"
        , "Guelma"
        , "Illizi"
        , "Jijel"
        , "Khenchela"
        , "Laghouat"
        , "Mascara"
        , "Médéa"
        , "Mila"
        , "Mostaganem"
        , "M'Sila"
        , "Naâma"
        , "Oran"
        , "Ouargla"
        , "Oum El Bouaghi"
        , "Relizane"
        , "Saïda"
        , "Sétif"
        , "Sidi Bel Abbès"
        , "Skikda"
        , "Souk Ahras"
        , "Tamanrasset"
        , "Tébessa"
        , "Tiaret"
        , "Tindouf"
        , "Tipaza"
        , "Tissemsilt"
        , "Tizi Ouzou"
        , "Tlemcen"
      ],
      doctorList1: [],
      clickOption: 0,
      typeSearch: 0,
      searchClassRow: "col-md-8 col-sm-12 col-md-offset-2 slider-text"
      // serachPage:false
    };
  },
  computed: {
    specialitites() {
      return this.$t('specList')
    },
  },
  created() {
    if (this.typePage == "subHeroNoSearch") {
      this.classHeader = "subHeroNoSearch";
    } else if (this.typePage == "subHero") {
      this.searchClassRow = "col-md-12 col-sm-12 slider-text";
      this.classHeader = "subHero";
      this.typeSearch = 1;
    } else if (this.typePage == "subHero-btn") {
      this.classHeader = "subHero";
      this.searchClassRow = "col-md-12 col-sm-12 slider-text";
      // this.serachPage=true
      this.typeSearch = 1;
    } else {
      this.classHeader = "";
    }

    axios
      .get("https://algedoc-backend.herokuapp.com/api/doctors/")
      .then((response) => {
        response.data.forEach(element => {
          if (element.doctorAdminValidation == true) {
            this.allDoctorData.push(element)
            this.doctorList1.push(element)
            this.doctorList2Filter(element)
          }
        });
        this.doctorList = this.doctorList2
      })
      .catch((error) => {
        this.errorMessage = error.message;
      });
  },

  methods: {
    getSpecialitites(spe) {
      for (let index = 0; index < this.specialitites.length; index++) {
        if (index == spe) {
          return this.specialitites[index]
        }
      }
    },
    doctorList2Filter(element) {
      if (element.city && element.address) {
        this.doctorList2.push(element.city.toString() + ", " + element.address.toString())
        this.doctorList2.sort()
      }
    },

    testFocus() {
      setTimeout(() => {
        console.log(this.clickOption)
        if (this.clickOption == 0) {
          this.searchStyle1 = 'NoDisplay'
          this.searchStyle2 = 'NoDisplay'
        }
        this.clickOption = 0
      }, 500000);
    },
    testInput(type) {
      if ((type == '1') && (this.search1 != "")) {
        if (this.typeSearch == 1) {
          this.searchStyle1 = "dropdownSerachOption serachHeroDoctor"
        } else {
          this.searchStyle1 = "dropdownSerachOptionHome serachHeroDoctor"
        }
        this.searchStyle2 = 'NoDisplay'
      } else if ((type == '2') && (this.search2 != "")) {
        if (this.typeSearch == 1) {
          this.searchStyle2 = "dropdownSerachOption2 serachHeroDoctor"
        } else {
          this.searchStyle2 = "dropdownSerachOption2Home serachHeroDoctor"
        }
        this.searchStyle1 = 'NoDisplay'
      }
    },
    searchSelectedValue(element) {
      this.clickOption = 1;
      const words = element.split(',')
      this.search4 = element
      if (words[1] == undefined) {
        this.search2 = null
      } else {
        this.search2 = words[1]
      }
      this.search3 = words[0]
    },
    linkSerach() {
      this.clickOption = 0
      // if (this.search4 == undefined || this.search4 == "") {
      //   return "/search?s1=" + null + "&s2=" + null + "&s3=" + null
      // } else {
      //   return "/search?s1=" + this.search1 + "&s2=" + this.search2 + "&s3=" + this.search3
      // }
      return "/search?s1=" + this.search1 + "&s2=" + this.search2 + "&s3=" + this.search3
    },
    doctorLink(id) {
      return "/doctor-info?docId=" + id;
    },
    doctorImg(element) {
      if (!element.photo) {
        if (element.gender == "homme") {
          return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611628277/t%C3%A9l%C3%A9chargement_yo46pz.jpg";
        } else if (element.gender == "femme") {
          return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611690556/2ac5343c-2653-4e20-862a-6205d4e6fa61_lict1f.png";
        }
      } else {
        return (
          "https://res.cloudinary.com/dba2dcapc/image/upload/v1611178444/" +
          element.photo
        );
      }
    },
    async getSearchResult(type) {
      if (type == '1') {
        this.doctorList1 = this.allDoctorData
        this.searchStyle2 = "NoDisplay";
        if (this.typeSearch == 1) {
          this.searchStyle1 = "dropdownSerachOption serachHeroDoctor"
        } else {
          this.searchStyle1 = "dropdownSerachOptionHome serachHeroDoctor"
        }
        var valueToSerach = "";
        var allSpe = ""
        this.doctorList1 = await this.doctorList1.filter((element) => {
          allSpe = element.specialty.toString();
          valueToSerach = element.lastName + " " + element.firstName + allSpe;
          return this.search1
            .toLowerCase()
            .split(" ")
            .every((v) => valueToSerach.toLowerCase().includes(v));
        });
      } else {
        this.doctorList2 = this.doctorList
        if (this.typeSearch == 1) {
          this.searchStyle2 = "dropdownSerachOption2 serachHeroDoctor";
        } else {
          this.searchStyle2 = "dropdownSerachOption2Home serachHeroDoctor"
        }
        this.searchStyle1 = "NoDisplay"
        var allCity = "";
        var allAdresse = "";
        var valueToSerach = "";
        this.doctorList2 = await this.doctorList2.filter((element) => {
          // if (element.city) {
          //   allCity = element.city.toString();
          // }
          // if (element.address) {
          //   allAdresse = element.address.toString();
          // }
          // valueToSerach = allAdresse + " " + allCity;
          return this.search4
            .toLowerCase()
            .split(" ")
            .every((v) => element.toLowerCase().includes(v));
        });
        // return this.dataToFilter;
      }

    }
  },
};
