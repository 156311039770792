import loginAndRegisterDoctorSection from "../../components/loginAndRegisterDoctorSection/index.vue";
import heroSection from "../../components/heroSection/index.vue";

export default {
  name: "LoginAndRegisterDoctorPage",
  components: {
    heroSection,
    loginAndRegisterDoctorSection,
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: 'Doctor login registration page',
      })
      this.$gtag.pageview({
        page_path: '/doctorlogin',
      })
    }
  }
};
