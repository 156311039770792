import axios from "axios";
export default {
  name: "blogSection",

  data: () => {
    return {
      blogData: {}
    }
  },
  created() {
    axios
      .get(
        "https://algedoc-backend.herokuapp.com/api/articles/" + this.$router.history.current.query.blogId
      )
      .then((response) => {
        console.log(response)
        this.blogData = response.data.articles
      })
      .catch((error) => {
        console.log("blog details probleme")
        this.errorMessage = error.message;
      });
  },
  methods: {
    articleDate(date) {
      var dateBlog = new Date(date);
      var day = dateBlog.getDate();
      var month = dateBlog.getMonth() + 1;
      var years = dateBlog.getFullYear();
      return years + "-" + this.filterDate(month) + "-" + this.filterDate(day)
    },
    filterDate(valueToChange) {
      if (valueToChange >= 0 && valueToChange < 9) {
        valueToChange = "0" + valueToChange.toString();
      }
      return valueToChange;
    },
    articleImg(img) {
      return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611354830/" + img
    },
    articleLink(id) {
      return "/blog?blogId=" + id
    }
  }
};
