import axios from "axios";

export default {
  name: "newsLetters",
  data: () => {
    return {
      email: ""
    }
  },
  methods: {
    newsLetter() {
      var payload = {
        email: this.email
      }
      axios
        .post(
          "https://algedoc-backend.herokuapp.com/api/newsletters",
          payload
        )
        .then((response) => {
          this.email = ""
          this.$toast.open({
            message: this.$t('notifications.newsLetterSent'),
            type: 'success',
            duration: 5000
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.$toast.open({
            message: this.$t('notifications.emailExists'),
            type: 'error',
            duration: 7000
            // all of other options may go here
          });
        });
    }
  }
};
