import axios from "axios";
export default {
  name: "contactSection",
  data() {
    return {
      contact: {
        lastName: "",
        firstName: "",
        email: "",
        subject: "",
        message: ""
      }
    }
  },
  methods: {
    sendContact(e) {
      e.preventDefault()
      axios
        .post(
          "https://algedoc-backend.herokuapp.com/api/usercontacts", this.contact
        )
        .then((response) => {
          this.$toast.open({
            message: this.$t('notifications.messageSent'),
            type: "success",
            duration: 5000
          });
          this.contact.lastName = "",
            this.contact.firstName = "",
            this.contact.email = "",
            this.contact.subject = "",
            this.contact.message = ""
        })
        .catch((error) => {
          this.$toast.open({
            message: this.$t('notifications.messageNotSent'),
            type: "error",
            duration: 7000
          });
          this.errorMessage = error.message;
        });
    }
  }
};
