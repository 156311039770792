import VueAos from "vue-aos";
import axios from "axios";

export default {
  name: "counterSection",
  components: { VueAos },
  data() {
    return {
      stat: {
      }
    }
  },
  created() {
    axios.get("https://algedoc-backend.herokuapp.com/api/statistics")
      .then(response => {
        this.stat = response.data;
      })
      .catch(error => {

      });
  }
};
