import pro from "../../components/professionnel/index.vue";
import heroSection from "../../components/heroSection/index.vue";

export default {
  name: "professionnel",
  components: {
    pro,
    heroSection
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: 'Professional page',
      })
      this.$gtag.pageview({
        page_path: '/professionel',
      })
    }
  }
};
