import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCarousel from "vue-carousel";
import i18n from "./i18n";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import rate from 'vue-rate'
import 'vue-rate/dist/vue-rate.css'
import VueLoading from 'vuejs-loading-plugin'
 
 
// overwrite defaults
Vue.use(VueLoading, {
  // dark: true, // default false
  text: 'Chargement', // default 'Loading'
  // customLoader: myVueComponent, // replaces the spinner and text with your own
  // classes: ['myclass'] // array, object or string
})


Vue.use(rate)
Vue.use(VueToast);
Vue.use(VueCarousel);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
