import heroSection from "../../components/heroSection/index.vue";
import newPasswordSection from "../../components/newPasswordSection/index.vue";


export default {
  name: "newPasswordPage",
  components: {
    newPasswordSection,
    heroSection
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: 'New password change',
      })
      this.$gtag.pageview({
        page_path: '/newpassword',
      })
    }
  }
};
