import heroSection from "../../components/heroSection/index.vue";
import stepsSection from "../../components/stepsSection/index.vue";
import speSection from "../../components/speSection/index.vue";
import counterSection from "../../components/counterSection/index.vue";
import newSection from "../../components/newSection/index.vue";
import newsLetters from "../../components/newsLetters/index.vue";
import partenairesSection from "../../components/partenairesSection/index.vue";
import proSection from "../../components/proSection/index.vue";


export default {
  name: "homePage",
  components: {
    heroSection,
    speSection,
    stepsSection,
    proSection,
    counterSection,
    newSection,
    newsLetters,
    partenairesSection
  },
  methods: {
    track() {
      this.$gtag.screenview({
        screen_name: 'Home page',
      })
      this.$gtag.pageview({
        page_path: '/home',
      })
    }
  }
};
