import axios from "axios";

export default {
  name: "newPasswordSection",
  data: () => {
    return {
      confirmPassword: null,
      password: null,
    }
  },
  created() {
    var newHash = this.$router.history.current.query.hash.replace(/\s/g, '+');
    var payloadVerify = {
      email: this.$router.history.current.query.email,
      hash: newHash
    }
    console.log(payloadVerify, this.$router)
    axios
      .post(
        "https://algedoc-backend.herokuapp.com/api/user/verifyRecoverPasswordRequest", payloadVerify
      )
      .then((response) => {
        // this.$toast.open({
        //   message: "Tapez votre nouveau mot de passe",
        //   type: "success",
        //   duration: 7000
        //   // position: "top-right",
        // });
      })
      .catch((error) => {
        console.log("hash probleme")
        this.errorMessage = error.message;
        this.$router.push({ name: 'Home' })
      });
  },
  methods: {
    updateUserData() {
      var payload = {
        email: this.$router.history.current.query.email,
        hash: this.$router.history.current.query.hash.replace(/\s/g, '+'),
        password: this.password,
        confirmPassword: this.confirmPassword
      }
      axios
        .post(
          "https://algedoc-backend.herokuapp.com/api/user/recoverPassword", payload
        )
        .then((response) => {
          this.$toast.open({
            message: this.$t('notifications.passwordUpdated'),
            type: "success",
            duration: 5000
            // position: "top-right",
          });
          this.$router.push({ name: 'Home' })
        })
        .catch((error) => {
          console.log("blog probleme")
          this.errorMessage = error.message;
          this.$toast.open({
            message: this.$t('notifications.updateError'),
            type: "error",
            duration: 7000
            // position: "top-right",
          });
        });
    },
  }
};
