import VueAos from "vue-aos";
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "stepsSection",
  components: { VueAos, Carousel, Slide
  },
  data() {
    return {
      imgDoc0: require("./user/inscrire.svg"),
      pDoc0:'p-hide',
      pClass0:"p-Notselected",
      imgDoc1: require("./user/medecin-pro.png"),
      pDoc1:'p-hide',
      pClass1:"p-Notselected",
      imgDoc2: require("./user/rendez-vous.png"),
      pDoc2:'p-hide',
      pClass2:"p-Notselected",
      imgDoc3: require("./user/confirmer.png"),
      pDoc3:'p-hide',
      pClass3:"p-Notselected",
      imgStyle0:"img-style0",
      imgStyle1:"img-style1",
      imgStyle2:"img-style2",
      imgStyle3:"img-style3"
    }
  },
  methods:{
    // imgDoctor1(){
    //   return this.imgDoc1
    // }
  }
  
};