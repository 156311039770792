import Vue from "vue";
import VueRouter from "vue-router";
import layout from "../views/layout/index.vue";
import homePage from "../views/homePage/index.vue";
import blogPage from "../views/blogPage/index.vue";
import searchPage from "../views/searchPage/index.vue";
import doctorPage from "../views/doctorPage/index.vue";
import patientPage from "../views/patientPage/index.vue";
// import lastViewedPage from "../views/lastViewedPage/index.vue";
import conditionPage from "../views/conditionPage/index.vue";
import contactPage from "../views/contactPage/index.vue";
import faqPage from "../views/faqPage/index.vue";
import LoginAndRegisterDoctorPage from "../views/LoginAndRegisterDoctorPage/index.vue";
import LoginAndRegisterUserPage from "../views/LoginAndRegisterUserPage/index.vue";
import userInfoPage from "../views/userInfoPage/index.vue";
import updatePasswordPage from "../views/updatePasswordPage/index.vue";
import newPasswordPage from "../views/newPasswordPage/index.vue";
import chatPage from "../views/chatPage/Chat";
import articles from "../views/articlesPage/index.vue";
import specList from "../views/specListPage/index.vue";
import professionnel from "../views/professionnel/index.vue";
import poliquePage from "../views/poliquePage/index.vue";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: layout,
    children: [
      {
        path: "/",
        name: "Home",
        component: homePage
      },
      {
        path: "/blog",
        name: "Blog",
        component: blogPage
      },
      {
        path: "/search",
        name: "search",
        component: searchPage
      },
      {
        path: "/doctor-info",
        name: "doctor-info",
        component: doctorPage
      },
      {
        path: "/articles",
        name: "articles",
        component: articles
      },
      {
        path: "/speclist",
        name: "specList",
        component: specList
      },
      {
        path: "/professionnel",
        name: "professionnel",
        component: professionnel
      },
      {
        path: "/chat",
        name: "chat",
        component: chatPage,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem("algerieDok-U")) {
            next();
          } else {
            Vue.$toast.open({
              message: "Vous devez être connecté",
              type: "info",
              // position: "top-right",
              // all of other options may go here
            });
            window.location.replace('/');

          }
        },
      },
      {
        path: "/patient",
        name: "patient",
        component: patientPage,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem("algerieDok-U")) {
            next();
          } else {
            Vue.$toast.open({
              message: "Vous devez être connecté",
              type: "info",
              // position: "top-right",
              // all of other options may go here
            });
            window.location.replace('/');
          }
        },
      },
      {
        path: "/password",
        name: "modifier mot de passe",
        component: updatePasswordPage,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem("algerieDok-U")) {
            next();
          } else {
            Vue.$toast.open({
              message: "Vous devez être connecté",
              type: "info",
              // position: "top-right",
              // all of other options may go here
            });
            window.location.replace('/');
          }
        },
      },
      // {
      //   path: "/lastViewedPage",
      //   name: "lastViewedPage",
      //   component: lastViewedPage
      // },
      {
        path: "/userInfoPage",
        name: "userInfoPage",
        component: userInfoPage,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem("algerieDok-U")) {
            next();
          } else {
            Vue.$toast.open({
              message: "Vous devez être connecté",
              type: "info",
              // position: "top-right",
              // all of other options may go here
            });
            window.location.replace('/');

          }
        },
      },
      {
        path: "/condition",
        name: "Condition",
        component: conditionPage
      },
      {
        path: "/polique",
        name: "polique",
        component: poliquePage
      },

      {
        path: "/faq",
        name: "faq",
        component: faqPage
      },
      {
        path: "/contact",
        name: "contact",
        component: contactPage
      },
      {
        path: "/LoginAndRegisterDoctorPage",
        name: "Inscription praticien",
        component: LoginAndRegisterDoctorPage,
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("algerieDok-U")) {
            next();
          } else {
            Vue.$toast.open({
              message: "Vous devez être déconnecté",
              type: "info",
              // position: "top-right",
              // all of other options may go here
            });
            window.location.replace('/');

          }
        },
      },
      {
        path: "/newPassword",
        name: "new Password",
        component: newPasswordPage,
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("algerieDok-U")) {
            next();
          } else {
            Vue.$toast.open({
              message: "Vous devez être déconnecté",
              type: "info",
              // position: "top-right",
              // all of other options may go here
            });
            window.location.replace('/');

          }
        },
      },

      {
        path: "/LoginAndRegisterUserPage",
        name: "Inscription patient",
        component: LoginAndRegisterUserPage,
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("algerieDok-U")) {
            next();
          } else {
            Vue.$toast.open({
              message: "Vous devez être déconnecté",
              type: "info",
              // position: "top-right",
              // all of other options may go here
            });
            window.location.replace('/');

          }
        },
      },

    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

// router.beforeResolve((next) => {
//   // If this isn't an initial page load.
//   // Start the route progress bar.
//   router.app.$nprogress.start();
//   next();
// });

// router.afterEach(() => {
//   // Complete the animation of the route progress bar.
//   router.app.$nprogress.done();
// });

export default router;
