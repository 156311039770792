import axios from "axios";
import Datepicker from 'vuejs-datepicker';
import { en, fr } from 'vuejs-datepicker/dist/locale'
import 'vue-rate/dist/vue-rate.css'

export default {
  name: "doctorSection",
  components: {
    Datepicker
  },
  data() {
    return {
      dateClass: "date-doctor-display row",
      idGridTime: "",
      doctorData: {},
      messageUser: "",
      reviewDoctor: [],
      en: en,
      fr: fr,
      timeTable: [],
      state: {
        date: new Date(),
        selectedDate: new Date(),
        disabledDates: {
          to: new Date(),
          days: []
        }
      },
      selectedTime: null,
      value: null,
      validated: true,
      autre: false,
      test: true,
      page2: true,
      finish: true,
      userData: {},
      dateToChange: 0,
      filled: true,
      dateUserFormat: new Date(),
      position: null,
      map: null,
      myPosition: {},
      lat: null,
      lng: null,
      directionsService: null,
      directionsRenderer: null,
      // myRate: 0,
      // myRateButton: false,
      // rateUser: false,
      timeTableProfileMorning: [],
      timeTableProfileEvening: []
    };
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newVal) {
        this.getDoctorInfo();
      },
    },
  },
  computed: {
    dateOfUser: {
      get() {
        var dateUser = new Date(this.dateUserFormat);
        var day = dateUser.getDate();
        if (day >= 0 && day <= 9) {
          day = "0" + day.toString();
        }
        var month = dateUser.getMonth() + 1;
        if (month >= 0 && month <= 9) {
          month = "0" + month.toString();
        }
        var years = dateUser.getFullYear();
        this.dateUserFormat = years + "-" + month + "-" + day;
        return this.dateUserFormat;
      },
    },
  },
  created() {
    this.getDoctorInfo()
    this.reviewDoc();
  },
  async mounted() {
    setTimeout(() => {
      this.initMap();
    }, 2000);
  },
  methods: {
    directionClass() {
      if (this.$store.state.locale == 'ar') return 'directionRight rendez-vous'
      else return 'directionLeft rendez-vous'
    },
    confirmDate() {
      if (!this.selectedTime) {
        this.$toast.open({
          message: this.$t('notifications.selectTimeError'),
          type: "error",
          // position: "top-right",
          // all of other options may go here
        });
      } else {
        this.finish = !this.finish
        this.page2 = !this.page2
      }
    },
    testLoginUser() {
      if ((localStorage.getItem("algerieDok-U")) && (localStorage.getItem("algerieDok-R") != "doctor")) {
        return true
      } else {
        return false
      }
    },
    notifUser() {
      if (localStorage.getItem("algerieDok-R") != "doctor") {
        this.$toast.open({
          message: this.$t('notifications.notConnectedError'),
          type: "info",
          // position: "top-right",
          // all of other options may go here
        });
      } else {
        this.$toast.open({
          message: this.$t('notifications.notPatientError'),
          type: "info",
          duration: 5000
          // position: "top-right",
          // all of other options may go here
        });
      }
    },
    getDate(date) {
      return new Date(date).toLocaleDateString()
    },
    async enterChat() {
      try {
        const chatRoom = await this.$store.dispatch('createRoom', this.$route.query.docId)
        // console.log(chatRoom);
        await this.$router.push({ path: `/chat?roomId=${chatRoom._id}` })
      }
      catch (err) {
        console.log(err);
      }
    },
    onBeforeRate(rate) {
      alert(rate)
    },
    onAfterRate(rate) {
      alert(rate)
    },
    iconSocialNetwork(nameSocial) {
      var result = ""
      if (nameSocial == "facebook") {
        result = "<i class='fa fa-facebook' style='margin-right:8px;color:#4267B2; border:2px solid #4267B2;padding:12px;border-radius:50%;height:20px;width:20px;display:flex;align-items: center;justify-content: center; '></i> "
      }
      if (nameSocial == "twitter") {
        result = "<i class='fa fa-twitter' style='margin-right:8px;color:#1DA1F2; border:2px solid #1DA1F2;padding:12px;border-radius:50%;height:20px;width:20px;display:flex;align-items: center;justify-content: center; '></i> "
      }
      if (nameSocial == "linkedIn") {
        result = "<i class='fa fa-linkedin' style='margin-right:8px;color:#0e76a8; border:2px solid #0e76a8;padding:12px;border-radius:50%;height:20px;width:20px;display:flex;align-items: center;justify-content: center; '></i> "
      }
      if (nameSocial == "WhatsApp") {
        result = "<i class='fa fa-whatsapp' style='margin-right:8px;color:#075e54; border:2px solid #075e54;padding:12px;border-radius:50%;height:20px;width:20px;display:flex;align-items: center;justify-content: center; '></i> "
      }
      return result;
    },
    userImg(element) {
      console.log("element", element)
      if (!element.userId.photo) {
        if (element.userId.gender == "homme") {
          return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611628277/t%C3%A9l%C3%A9chargement_yo46pz.jpg"
        } else if (element.userId.gender == "femme") {
          return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611690556/2ac5343c-2653-4e20-862a-6205d4e6fa61_lict1f.png"
        }
      } else {
        return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611178444/" + element.userId.photo
      }
    },
    initMap() {
      const directionsRenderer = new google.maps.DirectionsRenderer();
      const directionsService = new google.maps.DirectionsService();
      let position = null;
      if (this.doctorData.position)
        position = {
          lat: parseFloat(this.doctorData.position.lat),
          lng: parseFloat(this.doctorData.position.lng),
        };
      let myLatlng = position || {
        lat: 30.87587185903049,
        lng: 13.190554884293533,
      };
      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 7,
        center: myLatlng,
      });
      let marker = null;
      new google.maps.Marker({
        position: position,
        map,
        title: this.doctorData.address + ", " + this.doctorData.city,
      });
      directionsRenderer.setMap(map);
      this.lat = position.lat
      this.lng = position.lng
      this.directionsService = directionsService
      this.directionsRenderer = directionsRenderer

      // const onChangeHandler = function () {
      //   this.calculateAndDisplayRoute(directionsService, directionsRenderer,position.lat,position.lng);
      // };
      // document.getElementById("start").addEventListener("click", onChangeHandler);
      // document.getElementById("end").addEventListener("change", onChangeHandler);

    },
    // directionsService, directionsRenderer,lat,lng
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          var myLat = position.coords.latitude
          var myLng = position.coords.longitude
          this.directionsService.route(
            {
              origin: { lat: myLat, lng: myLng },
              destination: { lat: this.lat, lng: this.lng },
              travelMode: google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
              if (status === "OK") {
                this.directionsRenderer.setDirections(response);
              } else {
                window.alert("Directions request failed due to " + status);
              }
            }
          );
        });
      } else {
        x.innerHTML = "Geolocation is not supported by this browser.";
      }
    },
    // showPosition(position) {
    //  this.myPosition={lat:position.coords.latitude,lng:position.coords.longitude}
    // },
    async calculateAndDisplayRoute() {
      this.getLocation()

    },
    disabledDates() {
      var doctorInfo = this.doctorData;
      // axios
      //   .get(
      //     "https://algedoc-backend.herokuapp.com/api/doctors/" + this.doctorId,
      //   )
      //   .then((response) => {
      //     var doctor = response.data
      var days = [0, 1, 2, 3, 4, 5, 6]
      Object.values(doctorInfo.workSchedule).forEach(val => {
        days = days.filter(function (item) {
          return item !== val.day
        })
      });
      this.state.disabledDates.days = days
      // })
      // .catch((error) => {
      //   this.errorMessage = error.message;
      // });
    },
    saveNewData() {
      this.dateToChange = 0;
      var payload = {
        lastName: this.userData.lastName,
        firstName: this.userData.firstName,
        email: this.userData.email,
        phoneNumber: this.userData.phoneNumber,
        gender: this.userData.gender,
        dateOfBirth: this.userData.dateOfBirth
      }
      axios.put('https://algedoc-backend.herokuapp.com/api/patients/' + localStorage.getItem("algerieDok-U"), payload)
        .then(response => {
          this.$toast.open({
            message: this.$t('notifications.profileUpdated'),
            type: "success",
            // position: "top-right",
          });
        })
        .catch(error => {
          this.$toast.open({
            message: this.$t('notifications.updateError'),
            type: "error",
            duration: 5000
            // position: "top-right",
          });
          console.log("modifier utilisateur rdv")
          console.log(error);
        });
    },
    dateSelected() {
      this.idGridTime = "time"
      this.timeTable = []
      var dateToSplite = ""
      var doctorInfo = this.doctorData;
      var day = this.state.selectedDate.getDay()
      doctorInfo.workSchedule.forEach(infoDoc => {
        if (day == infoDoc.day) {
          if ((infoDoc.morning.start != null) && (infoDoc.morning.end != null)) {
            dateToSplite = "01/01/2021 " + infoDoc.morning.start + ":00"
            var dt = new Date(dateToSplite);
            do {
              var hours = dt.getHours()
              hours = this.formatDate(hours)
              var minute = dt.getMinutes()
              minute = this.formatDate(minute)
              this.timeTable.push(hours + ":" + minute)
              dt.setMinutes(dt.getMinutes() + 30);
            } while (hours < infoDoc.morning.end.split(":")[0])
          }
          if ((infoDoc.evening.start != null) && (infoDoc.evening.end != null)) {
            dateToSplite = "01/01/2021 " + infoDoc.evening.start + ":00"
            var dt = new Date(dateToSplite);
            do {
              var hours = dt.getHours()
              hours = this.formatDate(hours)
              var minute = dt.getMinutes()
              minute = this.formatDate(minute)
              this.timeTable.push(hours + ":" + minute)
              dt.setMinutes(dt.getMinutes() + 30);
            } while (hours < infoDoc.evening.end.split(":")[0])
          }
        }
      });
    },
    formatDate(value) {
      if (value >= 0 && value <= 9) {
        value = "0" + value.toString();
      }
      return value
    },
    autrePersonne() {
      this.userData.lastName = ''
      this.userData.firstName = ''
      this.userData.email = ''
      this.userData.phoneNumber = ''
      this.userData.gender = ''
      this.userData.dateOfBirth = ''
    },
    showPatientData() {
      this.selectedTime = null
      this.finish = true;
      this.page2 = true;
      this.idGridTime = ""
      this.timeTable = []
      this.test = true
      // this.doctorId = element
      axios
        .get(
          "https://algedoc-backend.herokuapp.com/api/patients/" + localStorage.getItem("algerieDok-U"),
        )
        .then((response) => {
          this.userData = response.data
          // this.disabledDates
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    checkTime(time) {
      this.selectedTime = time
    },
    checkDay(day) {
      this.selectedDay = day
    },
    createAppointment() {
      if ((this.selectedTime) || (this.userData.phoneNumber == "")) {
        if (this.autre) {
          var payload = {
            patientId: localStorage.getItem("algerieDok-U"),
            docId: this.$router.history.current.query.docId,
            guest: {
              lastName: this.userData.lastName,
              firstName: this.userData.firstName,
              email: this.userData.email,
              phoneNumber: this.userData.phoneNumber,
              gender: this.userData.gender,
              dateOfBirth: this.userData.dateOfBirth
            },
            date: {
              time: this.selectedTime,
              day: this.state.selectedDate
            }
          }
        } else {
          var payload = {
            patientId: localStorage.getItem("algerieDok-U"),
            docId: this.$router.history.current.query.docId,
            date: {
              time: this.selectedTime,
              day: this.state.selectedDate
            }
          }
        }
        console.log(payload, this.autre)
        axios
          .post(
            "https://algedoc-backend.herokuapp.com/api/appointments/", payload
          )
          .then((response) => {
            this.userData = response.data
            // this.disabledDates
            this.$toast.open({
              message: this.$t('notifications.appointmentConfirmed'),
              type: "success",
              duration: 7000
              // position: "top-right",
            });
            this.$store.dispatch('sendAppointmentNotification', payload.docId)
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.$toast.open({
              message: this.$t('notifications.appointmentError'),
              type: "error",
              duration: 7000
              // position: "top-right",
            });
          });
      } else {
        this.$toast.open({
          message: this.$t('notifications.appointmentError'),
          type: "error",
          duration: 7000
          // position: "top-right",
        });
      }
    },
    reviewDoc() {
      axios
        .get(
          "https://algedoc-backend.herokuapp.com/api/reviews/doctor/" +
          this.$router.history.current.query.docId
        )
        .then((response) => {
          this.reviewDoctor = response.data;
          console.log(this.reviewDoctor)
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    getDoctorInfo() {
      axios
        .get(
          "https://algedoc-backend.herokuapp.com/api/doctors/" +
          this.$router.history.current.query.docId
        )
        .then((response) => {
          this.timeTableProfileMorning = [];
          this.timeTableProfileEvening = [];
          this.doctorData = response.data;
          for (let index = 1; index <= 7; index++) {
            this.timeTableProfileMorningFull(this.timeTableProfileMorning.push(this.detailTime(this.doctorTime(index, "morning"))))
            this.timeTableProfileEveningFull(this.timeTableProfileEvening.push(this.detailTime(this.doctorTime(index, "evening"))))
          }
          console.log(response.data);
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    async timeTableProfileEveningFull() {
      var maxLength = []
      await this.timeTableProfileEvening.forEach(element => {
        maxLength.push(element.length)
      });
      await this.timeTableProfileEvening.forEach(element => {
        for (let index = 1; index <= Math.max(...maxLength); index++) {
          if (element[index] == undefined) {
            element.push("-")
          }
        }
      });
    },
    async timeTableProfileMorningFull() {
      var maxLength = []
      await this.timeTableProfileMorning.forEach(element => {
        maxLength.push(element.length)
      });
      await this.timeTableProfileMorning.forEach(element => {
        for (let index = 1; index <= Math.max(...maxLength); index++) {
          if (element[index] == undefined) {
            element.push("-")
          }
        }
      });
    },
    detailTime(time) {
      var res = time.split("-")
      var resultTable = []
      if (res[0] == 0) {
        resultTable.push("-")
      } else {
        res[0] = "01/01/2021 " + res[0]
        var newMinute = new Date(res[0])
        var index = 0;
        do {
          newMinute.setMinutes(newMinute.getMinutes() + 30 * index)
          var hours = newMinute.getHours();
          resultTable.push(this.formatDate(newMinute.getHours()) + ":" + this.formatDate(newMinute.getMinutes()))
          index++;
        } while (hours < res[1].split(":")[0])
      }
      return resultTable
    },
    doctorImg() {
      if (!this.doctorData.photo) {
        if (this.doctorData.gender == "homme") {
          return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611628277/t%C3%A9l%C3%A9chargement_yo46pz.jpg"
        } else if (this.doctorData.gender == "femme") {
          return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611690556/2ac5343c-2653-4e20-862a-6205d4e6fa61_lict1f.png"
        }
      } else {
        return "https://res.cloudinary.com/dba2dcapc/image/upload/v1611178444/" + this.doctorData.photo
      }
    },
    doctorSpe() {
      return this.doctorData.specialty.map(spec => this.$t(`specList[${spec}]`)).join(', ')
      // var result = "";
      // var tabSpe = this.doctorData.specialty;
      // this.doctorData.specialty.forEach(function (element, index) {
      //   if (index == tabSpe.length - 1) {
      //     result = result + element;
      //   } else {
      //     result = element + ", " + result;
      //   }
      // });
      // return result;
    },
    doctorLanguge() {
      return this.doctorData.spokenLanguage.join(', ')
      // var result = "";
      // var tabLan = this.doctorData.spokenLanguage;
      // this.doctorData.spokenLanguage.forEach(function (element, index) {
      //   if (index == tabLan.length - 1) {
      //     result = result + element;
      //   } else {
      //     result = element + ", " + result;
      //   }
      // });
      // return result;
    },
    // doctorPayment() {
    //   return "this.doctorData.paymentMethod.toString()"
    //   // var result = "";
    //   // var tabPay = this.doctorData.paymentMethod;
    //   // this.doctorData.paymentMethod.forEach(function (element, index) {
    //   //   if (index == tabPay.length - 1) {
    //   //     result = result + element;
    //   //   } else {
    //   //     result = element + ", " + result;
    //   //   }
    //   // });
    //   // return result;
    // },
    doctorTime(time, type) {
      if (time == 7) {
        time = 0
      }
      var result = "-";
      if (this.doctorData.workSchedule) {
        if (type == "morning") {
          this.doctorData.workSchedule.forEach(function (element, index) {
            if ((element.morning.end != null) || (element.morning.start != null)) {
              if (element.day == time) {
                result = element.morning.start + "-" + element.morning.end;
              }
            }
          });
        }
        if (type == "evening") {
          this.doctorData.workSchedule.forEach(function (element, index) {
            if ((element.evening.end != null) || (element.evening.start != null)) {
              if (element.day == time) {
                result = element.evening.start + "-" + element.evening.end;
              }
            }
          });
        }
      }
      return result;
    },
    verifyDokInfo() {
      if (
        (this.doctorData.spokenLanguage.length == 0) &&
        (this.doctorData.paymentMethod.length == 0)
      ) {
        return false;
      }
    },

    addComment() {
      if (localStorage.getItem("algerieDok-U")) {
        var payload = {
          userId: localStorage.getItem("algerieDok-U"),
          doctorId: this.$router.history.current.query.docId,
          comment: this.messageUser,
        }
        console.log(payload)
        axios
          .post(
            "https://algedoc-backend.herokuapp.com/api/reviews/", payload
          )
          .then((response) => {
            this.reviewDoc();
            this.$toast.open({
              message: this.$t('notifications.opinionSent'),
              type: "success",
              // position: "top-right",
            });
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.$toast.open({
              message: this.$t('notifications.opinionNotSent'),
              type: "error",
              // position: "top-right",
            });
          });
      } else {
        this.$toast.open({
          message: this.$t('notifications.notConnectedError'),
          type: "info",
          // position: "top-right",
          // all of other options may go here
        });
      }

    },
  },
};
